import React, { useEffect, useState } from "react";
import InputBuscar from "../../../fragments/Inputs/InputBuscar";

const FILTRO_LISTA_DIFUSION_INITIAL_VALUES = {
  busqueda: "",
};

const ListasDifusionFilter = ({
  listas,
  listasFiltro,
  setListasFiltro,
  currentPage,
  itemsPerPage,
}) => {
  const [filtro, setFiltro] = useState(FILTRO_LISTA_DIFUSION_INITIAL_VALUES);

  const totalItemsUntilNow = Math.min(
    currentPage * itemsPerPage,
    listasFiltro?.length
  );

  const filterDataBusqueda = (listas, busqueda) => {
    if (busqueda === "") return listas;

    const newListasFiltro = listas.filter((lista) =>
      lista.nombre.toLowerCase().includes(busqueda)
    );
    return newListasFiltro;
  };

  useEffect(() => {
    let newListasFiltro = listas;
    newListasFiltro = filterDataBusqueda(
      newListasFiltro,
      filtro.busqueda.toLowerCase()
    );
    setListasFiltro(newListasFiltro);
  }, [filtro, listas]);

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {totalItemsUntilNow} / {listasFiltro.length}
          </p>
          <p className="text-black">Listas de difusión</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 items-end lg:items-start">
        <div className="flex-shrink-0 mt-3 lg:mt-0 w-full">
          <InputBuscar
            onChange={(e) => setFiltro({ ...filtro, busqueda: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

export default ListasDifusionFilter;
