import React, {Component} from 'react';
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { repeatedColors } from './ActividadesUtils/ActividadesUtils';
import TooltipLabel from '../../../utils/TooltipLabel';
import { toCleanText, extractAccents } from './ActividadesUtils/ActividadesUtils';
import { Trans } from 'react-i18next';

function getRandomWords(list) {
  let lst = [];
  for(var i = 0; i < list.length ; i++) {
    let currItem = list[Math.floor(Math.random()*list.length)];
    if(lst.indexOf(currItem) === -1) {
      lst.push(currItem)
    } else {
      i--;
    }
  }
  return lst.sort((a,b) => {return b.length - a.length});
}

function WSGenerator(act, wordList, directions) {
  const reducedList = act.actData.filter((el)=>(el.length > 1))
  .map((el)=>{
    /* if(act.dificultad !== 1){
      return toCleanText(el)
    }else{
      return el
    } */
    return el
  })
  const completeList =  [...new Set(reducedList)];
  var clen = completeList.length;
  var clsIndex = Math.floor(Math.random()*(clen - 11));
	this.gridSize = 0;
	this.gridArr = [];
  const dificultad = act.dificultad
  const facil = [1, 2]
  const medio = [1 , 2 , 3]
  const dificil = [-4,-3,-2,-1,1,2,3,4]
	this.directions = dificultad === 1 ? facil : dificultad === 2 ? medio : dificultad === 3 ? dificil : medio
	this.wordList = (wordList && wordList.length) ? wordList : getRandomWords(completeList);
  this.wordList = this.wordList.map((item, i) => {
    return {text: item, index: i, found: false};
  })
	this.alreadyFound = [];
	this.startBox = null;
	this.endBox = null;

}

WSGenerator.prototype.getRandomRow = function() {
	return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomColumn = function() {
	return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomDirection = function() {
	return this.directions[Math.floor(Math.random() * this.directions.length)];
}

WSGenerator.prototype.setGridSize = function() {
  let len = this.wordList.length;
  let list = this.wordList.slice();
  let currLen = len;
  for(let i = 0; i < len; i++) {
    if(list[i].text.length > currLen) {
      currLen = list[i].text.length;
    }
  }
  this.gridSize = currLen + 3;
}

WSGenerator.prototype.initGrid = function() {
  let grid = [];
  for(let i = 0; i < this.gridSize; i++) {
    grid[i] = [];
    for(let j = 0; j < this.gridSize; j++) {
      grid[i][j] = "$$";
    }
  }
  this.gridArr = grid.slice();
  for(var i = 0; i < this.wordList.length; i++) {
    this.populateWord(this.wordList[i].text, i);
  }
  this.populateUnusedBoxes();
}

WSGenerator.prototype.isPlacable = function(word, start, end, direction, increment) {
  let i = 0, wordLength = word.length;
  let currI = start.x, currJ = start.y;
  while(currI >= 0 && currI < this.gridSize && currJ >= 0 && currJ < this.gridSize && i < wordLength && (this.gridArr[currI][currJ] === word[i] || this.gridArr[currI][currJ] === "$$")) {
    i++;
    switch(direction) {
      case -1: {
        currJ = currJ - 1;
        break;
      }
      case 1: {
        currJ++;
        break;
      }
      case -2: {
        currI--;
        break;
      }
      case 2: {
        currI++;
        break;
      }
      case 3: {
        currI++;
        currJ++;
        break;
      }
      case -3: {
        currI--;
        currJ--;
        break;
      }
      case 4: {
        currI++;
        currJ--;
        break;
      }
      case -4: {
        currI--;
        currJ++;
        break;
      }
      default: {

      }
    }
  }
  return i === wordLength;
}

WSGenerator.prototype.placeWord = function (word, start, end, direction, increment, index) {
  let i = 0,
  wordLength = word.length;
  let currI = start.x, currJ = start.y;
  while(i < wordLength) {
    this.gridArr[currI][currJ] = {
      letter: word[i], id: (currI+1)+"-cell-"+(currJ+1), 
      used: false, 
      hilighted: false,
      bg:repeatedColors[index] + '80', 
      color:"#fff"
    };
    i++;
    switch(direction) {
      case -1: {
        currJ = currJ - 1;
        break;
      }
      case 1: {
        currJ++;
        break;
      }
      case -2: {
        currI--;
        break;
      }
      case 2: {
        currI++;
        break;
      }
      case 3: {
        currI++;
        currJ++;
        break;
      }
      case -3: {
        currI--;
        currJ--;
        break;
      }
      case 4: {
        currI++;
        currJ--;
        break;
      }
      case -4: {
        currI--;
        currJ++;
        break;
      }
      default: {

      }
    }
    
  }
}

WSGenerator.prototype.populateWord = function(word, i) {
  let start = {x: this.getRandomRow(), y: this.getRandomColumn()};
  let dir = this.getRandomDirection();
  
  if(this.isPlacable(word, start, null, dir, null)) {
    this.placeWord(word.toUpperCase(), start, null, dir, null, i);
  } else {
    this.populateWord(word, i);
  }
}

WSGenerator.prototype.populateUnusedBoxes = function() {
  let indexi;
  let indexj;
  for(indexi = 0; indexi < this.gridSize; indexi++) {
    for(indexj = 0; indexj < this.gridSize; indexj++) {
      if(this.gridArr[indexi][indexj] === "$$") {
        this.gridArr[indexi][indexj] = {
        	letter: [...WSGenerator.prototype.alphabets][Math.floor(Math.random() * 25)],
        	id: (indexi+1)+"-cell-"+(indexj+1),
          used: false,
          hilighted: false,
          bg:'#f8fdff',
          color:'#697d99'
        };
      }
    }
  }
}

WSGenerator.prototype.alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "R", "T", "U", "V", "W", "X", "Y", "Z"];

// Solver part
WSGenerator.prototype.getDirection = function(startObj, endObj) {
    var dir;
    let stRow = startObj.row,
    stCol = startObj.col,
    curRow = endObj.row,
    curCol = endObj.col;

    if (curRow === stRow && curCol !== stCol) {
        if (stCol < curCol) {
            dir = 1;
        } else {
            dir = -1;
        }
    } else if (curCol === stCol && curRow !== stRow) {
        if (stRow < curRow) {
            dir = 2;
        } else {
            dir = -2;
        }
    } else if (((curCol - stCol) === (curRow - stRow)) || ((stCol - curCol) === (stRow - curRow))) {
        if (stRow < curRow && stCol < curCol) {
            dir = 3;
        } else if (stRow > curRow && stCol > curCol) {
            dir = -3;
        }
    } else if (((stRow - curRow) === (curCol - stCol)) || ((curCol - stCol) === (curRow - curRow))) {
        if (stRow < curRow && stCol > curCol) {
            dir = 4;
        } else if (stRow > curRow && stCol < curCol) {
            dir = -4;
        }
    }
    return dir ? dir : 0;
}

WSGenerator.prototype.getStringBetweenPoints = function(startBox, endBox) {
    var dir;
    dir = this.getDirection(startBox, endBox);
    return this.getStringByRowCol(startBox, endBox, dir);
}

WSGenerator.prototype.getStringByRowCol = function(startBox, endBox, dir) {
    var returnedString = "";
    var cellIds = [];
    let str = startBox.row, stc = startBox.col,
    	enr = endBox.row, enc = endBox.col;

    switch (dir) {
        case -1:
            {
                for (let k = stc; k >= enc; k -= 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str,k]);
                }
                break;
            }
        case 1:
            {
                for (let k = stc; k <= enc; k += 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str,k]);
                }
                break;
            }
        case -2:
            {
                for (let k = str; k >= enr; k -= 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k,stc]);
                }
                break;
            }
        case 2:
            {
                for (let k = str; k <= enr; k += 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k,stc]);
                }
                break;
            }
        case -3:
            {
                for (let k = str, j = stc; k >= enr, j >= enc; k -= 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k,j]);
                }
                break;
            }
        case 3:
            {
                for (let k = str, j = stc; k <= enr, j <= enc; k += 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k,j]);
                }
                break;
            }
        case -4:
            {
                for (let k = str, j = stc; k >= enr, j <= enc; k -= 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k,j]);
                }
                break;
            }
        case 4:
            {
                for (let k = str, j = stc; k <= enr, j >= enc; k += 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k,j]);
                }
                break;
            }
        default: {

        }
    }
    return {str: returnedString, ids: cellIds};
};

WSGenerator.prototype.TestString =function(testStr) { // match the found string with the elements of the words
    var str = testStr,
        reverseStr = "",
        matched = false,
        reverseMatched = false,
        matchFound = false,
        reverseMatchFound = false;

    for (let i = 0; i <= str.length; i += 1) {
        reverseStr = str.substring(i, i + 1) + reverseStr;
    }
    matched = this.matchString(str);
    reverseMatched = this.matchString(reverseStr);


    if(matched) {
        matchFound = this.isAlreadyFound(testStr);
    }
    if(reverseMatched) {
        reverseMatchFound = this.isAlreadyFound(reverseStr);
    }

    if(matched && !matchFound) {
        return {found: false, str: testStr, match: true};
    } else if(reverseMatched && !reverseMatchFound) {
        return {found: false, str: reverseStr, match: true};
    } else if(matchFound && reverseMatchFound){
        return {found: true, match: false};
    } else {
        return {found: false, match: false};
    }
}

WSGenerator.prototype.isAlreadyFound = function(str) {
    var count, found = false;
    for (count = 0; count < this.alreadyFound.length; count++) {
        if (str === this.alreadyFound[count]) {
            found = true;
            break;
        }
    }
    return found;
};


WSGenerator.prototype.matchString = function(str) {
    var matched = false;
    for (let count = 0; count < this.wordList.length; count++) {
        if (str.toUpperCase() === this.wordList[count].text.toUpperCase()) {
            matched = true;
            break;
        }
    }
    return matched;
};

WSGenerator.prototype.getBoxById = function(id) {
	let [row,col] = id.split("-cell-");
	row -= 1; // subtract for 0 based index
	col -= 1; // subtract for 0 based index
	return Object.assign({}, this.gridArr[row][col], {row: row, col: col});
};


//cell.js
class Cell extends React.Component {
  
  constructor(props) { 
    super(props);
    this.state = {
    	hilighted: false
    };

    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }
  mouseOver(evt) {
  	this.setState({
  		hilighted: true
  	})
  }


  static getDerivedStateFromProps(props, state) {
    if (state.hilighted && !props.hasSelectionStarted()) {
	  return {
	  	hilighted: false
	  }
	}
	return null;
  }


  mouseOut(evt) {
  	let id = evt.target.id;
  	let startBox = this.props.hasSelectionStarted();
  	let hilighted = false;
  	if(startBox && startBox.id === id) {
  		hilighted = true;
  	}
  	this.setState({
  		hilighted: hilighted
  	});
  }
  
  render() {
    let cell = this.props.cell;
    let id = cell.id;
    let currClass = `cell !border border-white `+ ((cell.used || this.state.hilighted) ? `hilighted` : "");
    const evaluable = this.props.evaluable
    return (
      <div className={currClass} id={id} style={!evaluable ? {background:this.props.cell.bg,color:this.props.cell.color} : (cell.used) ? {background:this.props.cell.bg,} : {}}  
        onMouseDown={this.props.selectionStart} 
        onMouseUp={this.props.selectionEnd} 
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
        
        onTouchStart={this.props.selectionEnd}
        onTouchEnd={this.props.selectionStart}
        onTouchMove={this.mouseOver}
        onTouchCancel={this.mouseOut}
        //onClick={this.props.selectionEnd}
      >
      {cell.letter}
      </div>
    )
  }
}

//row.js
class Row extends React.Component {

  render() {
    let row = this.props.row;
    return (
      <div className="row">
        {row.map((item, i) => {
          return (<Cell cell = {item} key={i} selectionStart={this.props.selectionStart} 
          	selectionEnd={this.props.selectionEnd} mouseOver={this.props.mouseOver}
            evaluable={this.props.evaluable}
            gridSize={this.props.gridSize}
          	hasSelectionStarted={this.props.hasSelectionStarted}
          	/>);
        })}
      </div>
    )
  }
}

//grid.js
class Grid extends React.Component {
  constructor(props) {
    super(props);

    this.ws = props.ws;
    this.ws.startBox = null;
    this.ws.endBox = null;

    this.selectionStart = this.selectionStart.bind(this);
    this.selectionEnd = this.selectionEnd.bind(this);
    this.hasSelectionStarted = this.hasSelectionStarted.bind(this);
    this.state = {
      ws: this.ws
    }
  }

  selectionStart(evt) {
    let id = evt.target.id;
    this.ws.startBox = this.ws.getBoxById(id);
    return false;
  }

  selectionEnd(evt) {
    //let dir;
    if (this.ws.startBox != null) {
       let id = evt.target.id;
       this.ws.endBox = this.ws.getBoxById(id);
    }
    if(this.ws.startBox && this.ws.endBox) {
      
      let strObj = this.ws.getStringBetweenPoints(this.ws.startBox, this.ws.endBox);
      let str = strObj.str;
      let obj = this.ws.TestString(str);
      if(obj.match && !obj.found) {
        this.ws.alreadyFound.push(str);
        this.ws.wordList.forEach((item) => {
          if((item.text.toLowerCase()===str.toLowerCase()) || (str.split("").reverse().join("").toLowerCase() === item.text.toLowerCase())) {
            item.found = true;
          }
        });
        strObj.ids.forEach((item) => {
          let [i,j] = item;
          this.ws.gridArr[i][j].used = true;
        });
      }

      this.ws.startBox = null;
      this.ws.endBox = null;
      this.setState({
        ws: this.ws
      });
      return false;
    }
  }

  hasSelectionStarted() {
    return this.ws.startBox;
  }

  render() {
    /* al las celdas se  */
    let gridStyle = {
      width: (42 * this.props.ws.gridSize)+"px",
    };
    let gridArr = this.props.ws.gridArr.slice();
    let wordList = this.props.ws.wordList.slice();
    let toastVisible = this.props.ws.wordList.length === this.props.ws.alreadyFound.length;
    return (
      <div id="root" className={`${this.props.ws.gridSize > 15 ? 'md:w-[80%] text-[18px]' : 'md:w-1/2 text-[14px]'} text-3xl flex flex-col-reverse lg:flex-row mx-auto`}>
      <div className="!w-full grid" style={gridStyle}>
          {gridArr.map((row, i) => {return (<Row row={row} rowIndex={i} key={i} 
            selectionStart={this.selectionStart} 
            evaluable={this.props.evaluable}
            gridSize={this.props.gridSize}
            selectionEnd={this.selectionEnd} hasSelectionStarted={this.hasSelectionStarted}/>);})}
      </div>
      <div id="word-list" className='md:md-0 tracking-[1px] bg-white p-[10px] ml-[5px] text-[#697d99] border border-[#9dd2ea] rounded-lg'>
        <ul>
          {wordList.map((item, i) => {
            let styleObj = {
              textDecoration: item.found ? "line-through" : "none"
            };
            return(<li key={i} style={styleObj} className="inline-block mr-6 text-sm md:text-sm lg:block lg:mr-0 m-[5px] pb-[10px] transition font-light rounded uppercase">{toCleanText(item.text)}</li>)
          })}
          </ul>
        
      </div>
        
      </div>
    );
  }
}

//wordsearch.js
class SopaDeLetras extends React.Component {
    static contextType = ActividadContext
    
    constructor(props) {
        super(props);
        this.state = {counter:'idk what to tell ya'};
        this.wsGenerator = new WSGenerator(props);
        this.wsGenerator.setGridSize();
        this.wsGenerator.initGrid();
        this.wsGenerator.populateUnusedBoxes();
    }

    render() {
      return (
        <div id="root-container" className={`w-full ${this.props.evaluable ? '' : 'pointer-events-none'}`}>
              {this.props.instrucciones && <div className='act-instructions mb-8'>{this.props.instrucciones}</div>}
              <div className="block md:hidden bg-white p-2 rounded-lg mx-auto shadow-lg mb-4 w-fit pointer-events-auto border">
                <TooltipLabel
                  title="Ayuda"
                  tooltip={'Para resolver esta actividad en tu dispositivo móvil deberás mantener presionada la primera y última letra de cada palabra'}
                  styles="text-[#697d99]"
                  direction={'left'}
                />
              </div>
            <div className="sm:mt-5"></div>
            <Grid 
              ws={this.wsGenerator} 
              evaluable={this.props.evaluable}
              gridSize={this.wsGenerator.gridSize}
            />
            {this.props.evaluable ? 
            <button
              className='mt-4 md:mt-8 border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit'
              onClick={()=>{
                if(this.wsGenerator.alreadyFound.length > 0){
                  const encontradasLength = this.wsGenerator.alreadyFound.length
                  const totalesLength = this.wsGenerator.wordList.length
                  const calificacion = encontradasLength / totalesLength * 100
                  this.context.postCalificacionActividad(calificacion)
                  if(this.props.umaBloque)this.props.guardarCalificacion(this.props.umaBloque, encontradasLength, JSON.stringify(this.wsGenerator.alreadyFound))
                }else{
                  this.context.postCalificacionActividad(null)
                }
              }}
            >
              <Trans>Calificar</Trans>
            </button>:null
            }
        </div>)
  }
}

export default SopaDeLetras