import React from 'react'
import { isEven, isOdd, shuffle, isImage, isVideo, getExpDesc, getExpTitle, filaUnoItems, filaDosItems, getNuevasPreguntas, getRubricas, toCleanText } from './Actividades/ActividadesUtils/ActividadesUtils';
import {v4 as uuidv4} from 'uuid'
import TextoVisor from '../../visor/bloques/Texto';
import ImagenVisor from '../../visor/bloques/Imagen';
import ImagenTextoVisor from '../../visor/bloques/ImagenTexto';
import VideoVisor from '../../visor/bloques/Video';
import VideoTextoVisor from '../../visor/bloques/VideoTexto';
import PdfVisor from '../../visor/bloques/Pdf';
import AudioVisor from '../../visor/bloques/Audio';
import CabeceraVisor from '../../visor/bloques/Cabecera';
import ColapsableVisor from '../../visor/bloques/Colapsable';
import NumActividadVisor from '../../visor/bloques/NumeroActividad';
import ActPreguntasVisor from './fragments/bloquevisor/actividades/ActividadPreguntas';
import ActRelacionarVisor from './fragments/bloquevisor/actividades/ActividadRelacionarColumnas';
import ActividadOrdOraciones  from './fragments/bloquevisor/actividades/ActividadOrdOraciones';
import EncontrarPalabraFaltante from './Actividades/EncontrarPalabraFaltante';
import IndicadorVisor from '../../visor/bloques/Indicador';
import ActWrapper from './Actividades/ActividadesUtils/ActWrapper';
import BlockWrapper from './Actividades/ActividadesUtils/BlockWrapper';
import RuletaVisor from '../../visor/bloques/Ruleta';
import MemoramaVisor from './Actividades/Memorama';
import CarouselVisor from './Actividades/CustomCarousel';
import ContentSwitcher from './Actividades/ContentSwitcher';
import RompecabezasVisor from './Actividades/Rompecabezas/Puzzle';
import ArrastrarAContenedoresVisor from './Actividades/ArrastrarAContenedores';
import ArrastrarEtiquetas from './fragments/bloquevisor/actividades/ArrastrarEtiquetas';
import ArrastrarMatriz from './Actividades/ArrastrarMatriz';
import CartasVisor from './Actividades/Cartas'
import ChecklistVisor from './Actividades/Checklist';
import EvidenciaVisor from './Actividades/EvidenciaBox';
import SopaDeLetrasVisor from './Actividades/SopaDeLetras';
import CrucigramaVisor from './Actividades/Crucigrama';
import HotspotVisor from './Actividades/Hotspot';
import EncontrarDiferenciasVisor from './Actividades/EncontrarDiferencias';
import HotspotMultipleVisor from './Actividades/HotspotMultiple';
import HerramientaDibujoVisor from './Actividades/HerramientaDibujo';
import HerramientaHabla from './Actividades/HerramientasExpOral/HerramientaHabla';
import HerramientaPronunciacion from './Actividades/HerramientasExpOral/HerramientaPronunciacion';
import HerramientaVelocidad from './Actividades/HerramientasExpOral/HerramientaVelocidad';
import HerramientaLectorTexto from './Actividades/HerramientaLectorTexto';
import EscuchaEscribe from './Actividades/EscuchaEscribe';
import IsSpeakingCompatible from './Actividades/ActividadesUtils/IsSpeakingCompatible';
import ContenedorArchivos from './Actividades/ContenedorArchivos';
import HTMLplayer from './Actividades/HTMLplayer';
import PreguntasAbiertas from './Actividades/PreguntasAbiertas';
import Autoevaluacion from './Actividades/Autoevaluación';
import Test from './fragments/bloques/Test';
import MemoramaForPDFs from './Actividades/MemoramaForPDFs';
import '../../../../src/colors.css'
import BarSlider from './Actividades/CarruselOptions/BarSlider';
import FlexSlider from './Actividades/CarruselOptions/FlexSlider';
import Tarjetas from './Actividades/Tarjetas';
import ModelViewer from './Actividades/ModelViewer/ModelViewer';
import FullScreen from './Actividades/ActividadesUtils/FullScreen/FullScreen';
import ImageComparator from './Actividades/ImageComparator';
import ClickAndLearn from './Actividades/ClickAndLearn/ClickAndLearn';
import LectorTextoMultiple from './Actividades/LectorTextoMultiple';

//preguntas
const getPreguntas = (preguntas) =>{
    const listadoPreguntas = preguntas
    .map((el)=>{
        if(!el.hasOwnProperty('tipoContenido')){
            return el
        }else if(isImage(el.tipoContenido)){
            return {...el, imagen:el.tipoContenido}
        }else if(isVideo(el.tipoContenido)){
            return {...el, video:el.tipoContenido}
        }else{
            return {...el, audio:el.tipoContenido}
        }
    })
    .sort((a, b)=>{
        if (a.orden > b.orden){
            return 1
        }else{
            return -1
        }
    })
    
    const listadoRespuestas = listadoPreguntas.map((item)=>(item.respuestas))
    .flat()
    .map((el)=>{
        if(!el.hasOwnProperty('tipoContenido')){
            return el
        }else if(isImage(el.tipoContenido)){
            return {...el, imagen:el.tipoContenido}
        }else if(isVideo(el.tipoContenido)){
            return {...el, video:el.tipoContenido}
        }else{
            return {...el, audio:el.tipoContenido}
        }
    })
    
    const result = listadoPreguntas.map((el)=>{
        const respuestasObj = el.respuestas.map(obj => listadoRespuestas.find(o => o._id === obj._id) || obj).map((v)=>({...v, parentId:el._id}))
        return {...el, respuestas:respuestasObj}
    })

    return result
}
//relacionar
const relacionarData = (data) =>{
    const result = data.map((item)=>(item.filas))
    .flat()
    .map((el)=>{
        if(!el.hasOwnProperty('path')){
            return el
        }else if(isImage(el.path)){
            return {...el, imagen:el.path}
        }else{
            return {...el, audio:el.path}
        }
    })
    return result
}

//memorama

const evenItems = (data) =>{
    const memorama = JSON.parse(data)
    const result = memorama.filter((item, index)=>{
      return isEven(index)
    }).map((el, i)=>({...el, orden:i}))
    return result
  }

  const oddItems = (data) =>{
    const memorama = JSON.parse(data)
    const result = memorama.filter((item, index)=>{
      return isOdd(index)
    }).map((el, i)=>({...el, orden:i}))
    return result
  }

//Arrastrar a contenedores

const getContenedores = (data) =>{
    const parsedData = JSON.parse(data)
    const contenedores = parsedData.contenedores.map((el)=>{
        return  {...el, items:[]}
    })

    return contenedores
}

const getContenedorItems = (data) =>{
    const respuestas = JSON.parse(data).items
    const contenedores = JSON.parse(data).contenedores

    const contenedorItems = contenedores.map((el)=>{
        return el.respuestas.map(obj => respuestas.find(o => o._id === obj) || obj).map((v)=>({...v, parentId:el._id, orden:el.orden}))
    }).flat()

    return contenedorItems
}

const getContenedoresCompletos = (data) =>{
    const parsedData = JSON.parse(data)
    
    const contenedoresCompletos = parsedData.contenedores.map((el)=>{
        const respuestasList = el.respuestas.map(obj => parsedData.items.find(o => o._id === obj) || obj).map((v)=>({...v, parentId:el._id}))
        return {...el, respuestas:respuestasList}
    })
    
    return contenedoresCompletos
}

function ModoVisor({e, arreglo, modoProfesor}) {
  return (
    <div className='w-full grid gap-y-3'>
        {
            e.bloque._id==='61fb5f323f30e059b82c3db6' ? 
            <CabeceraVisor texto={e.texto} imagen={e.imagen} tipo={e.ruleta}/> :
            e.bloque._id==='61fb5f323f30e059b82c3da8' ? 
            <TextoVisor texto={e.texto} background={e.ruleta}/>:
            e.bloque._id==='61fb5f323f30e059b82c3daa' ? 
            <ImagenVisor imagen={e.imagen}  pieDeFoto={e.pieDeFoto} medidas={e.texto} tipo={e.ruleta}/> :
            e.bloque._id==='61fb5f323f30e059b82c3dac' ? 
            <ImagenTextoVisor texto={e.texto} background={e.ruleta} imagen={e.imagen} pieDeFoto={e.pieDeFoto} invertir={e.invertir}/> : 
            e.bloque._id==='61fb5f323f30e059b82c3dae' ? 
            <VideoVisor  video={e.path}  pieDeFoto={e.pieDeFoto} medidas={e.texto}/>: 
            e.bloque._id==='61fb5f323f30e059b82c3db0' ? 
            <VideoTextoVisor video={e.path} texto={e.texto} background={e.ruleta} invertir={e.invertir} pieDeFoto={e.pieDeFoto}/>:
            e.bloque._id==='61fb5f323f30e059b82c3db2' ? 
            <PdfVisor pdf={e.path} pieDeFoto={e.pieDeFoto}/>:
            e.bloque._id==='61fb5f323f30e059b82c3db4' ? 
            <AudioVisor audio={e.path} pieDeFoto={e?.pieDeFoto}/>:
            e.bloque._id==='61fb5f323f30e059b82c3db8' ? 
            <ColapsableVisor tipo={e.ruleta} imagen={e.imagen} texto1={e.texto.split('|')[0]} texto2={e.texto.split('|')[1]} />:
            e.bloque._id==='61fb5f323f30e059b82c3dbc' ? 
            <IndicadorVisor imagen={e.imagen} texto={e.texto} tipo={e.ruleta} lineOptions={e.html}/>:
            e.bloque._id==='61fb5f323f30e059b82c3dbe' ? 
            <NumActividadVisor textoEditado={e.texto} imagen={e.imagen} texto={arreglo.filter((el)=>(el.bloque._id === "61fb5f323f30e059b82c3dbe")).map((el)=>(el._id)).indexOf(e._id) + 1}/>:
            //Preguntas nuevas
            e.bloque._id==='61fb5f323f30e059b82c3dc0' && e.preguntasYRespuestas && e.preguntasYRespuestas !== '[]' ? 
            <ActWrapper tipo={'preguntas'} modoPrevisualizar>
                <ActPreguntasVisor 
                    preguntas={getNuevasPreguntas(JSON.parse(e.preguntasYRespuestas).preguntas, JSON.parse(e.preguntasYRespuestas).respuestas)} 
                    evaluable={modoProfesor} 
                    esMultiple={JSON.parse(e.preguntasYRespuestas).tipoAct}
                    preguntasVisibles={JSON.parse(e.preguntasYRespuestas).preguntasVisibles}
                    instrucciones={JSON.parse(e.preguntasYRespuestas).indicaciones}
                    tipoOrden={JSON.parse(e.preguntasYRespuestas).tipoOrden}
                />
            </ActWrapper>:
            //Preguntas viejas 
            e.bloque._id==='61fb5f323f30e059b82c3dc0' && e.actividad && e.actividad.preguntas > 0 ? 
            <ActWrapper tipo={'preguntas'} modoPrevisualizar>
                <ActPreguntasVisor 
                    preguntas={getPreguntas(e.actividad.preguntas).map((el)=>({...el, respuestas:shuffle(el.respuestas)}))} 
                    evaluable={modoProfesor} 
                    esMultiple={getPreguntas(e.actividad.preguntas)[0].tipo.split('|')[1] === '2'}
                    preguntasVisibles={null}
                />
            </ActWrapper>:
            //Ruleta
            e.bloque._id==='61fb5f313f30e059b82c4da9' && e.ruleta !== undefined ? 
            <RuletaVisor  ruleta={JSON.parse(e.ruleta)}/> :
            //Carouse
            e.bloque._id==='624cffbff3d9d488c1231fb6' && e.memorama !== undefined ? 
            <>
                {
                    JSON.parse(e.ruleta)?.carouselType === 'SLIDER' ? <BarSlider data={JSON.parse(e.memorama).filter((e)=>(e))} instrucciones={e?.texto}/> :
                    JSON.parse(e.ruleta)?.carouselType === 'FLEX' ? <FlexSlider carouselData={JSON.parse(e.memorama).filter((e)=>(e))} instrucciones={e?.texto}/>:
                    <CarouselVisor carouselData={JSON.parse(e.memorama).filter((e)=>(e))} instrucciones={e?.texto}/> 
                }
            </>:
            //Memorama
            e.bloque._id==='61fb5f314f30e059b82c4da2' && e.memorama !== '[]' ? 
            <>
                {modoProfesor ? 
                <ActWrapper modoPrevisualizar tipo="memorama">
                    <MemoramaVisor data={shuffle([...oddItems(e.memorama), ...evenItems(e.memorama)])} evaluable={modoProfesor} instrucciones={e?.texto}/> 
                </ActWrapper>
                :
                <ActWrapper modoPrevisualizar tipo="memorama">
                <MemoramaForPDFs data={shuffle([...oddItems(e.memorama), ...evenItems(e.memorama)])} evaluable={modoProfesor} instrucciones={e?.texto}/> 
                </ActWrapper>}
            </>
            :
            //cartas
            e.bloque._id==='62b2557b50c6de4aafeba3b3' ? 
            <div className='cardsContainer my-10'>
                <div className='CardsGame flex ml-4 md:block md:m-0'>
                    <div className='transform scale-75 md:scale-100'>
                        {JSON.parse(e.cartas).map((item, index) => (
                            <CartasVisor data={item} index={index} key={index} />
                        ))}
                    </div>
                </div>
            </div>:
            e.bloque._id==='624cff4bf3d9d488c1231fb5' && e.actividad !== undefined && e.ruleta !== undefined ? 
            <ChecklistVisor
                title={JSON.parse(e.ruleta).titulo} 
                listData={e.actividad.checklists} 
                evaluable={modoProfesor}
                instrucciones={e?.texto}
            />:
             //Rompecabezas
             e.bloque._id==='61fb5f345f30e059b82c4da2' && JSON.parse(e.rompecabezas).length > 0 ? 
             <ActWrapper modoPrevisualizar tipo={'rompecabezas'}>
                <RompecabezasVisor  data={JSON.parse(e.rompecabezas)} evaluable={modoProfesor} instrucciones={e?.texto}/>
            </ActWrapper>:
             //Arrastrar
             e.bloque._id==="62b24f2450c6de4aafeba3b0" && e.arrastrarAContenedores !== undefined && e.ruleta !== undefined ? 
             <ActWrapper modoPrevisualizar tipo={'arrastrar'} >
                {JSON.parse(e.ruleta).tipoAct === 1 ? (
                    <ArrastrarAContenedoresVisor
                    contenedores={getContenedores(e.arrastrarAContenedores)}
                    contenedorItems={getContenedorItems(e.arrastrarAContenedores)}
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.ruleta)?.instrucciones}
                    />
                ) : JSON.parse(e.ruleta).tipoAct === 2 ? (
                    <ArrastrarEtiquetas
                    f1={getContenedores(e.arrastrarAContenedores)}
                    f2={getContenedorItems(e.arrastrarAContenedores)}
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.ruleta)?.instrucciones}
                    />
                ) : JSON.parse(e.ruleta).tipoAct === 3 ? (
                    <ArrastrarMatriz
                    f1={getContenedorItems(e.arrastrarAContenedores)}
                    f2={getContenedores(e.arrastrarAContenedores)}
                    contenedoresCompletos={getContenedoresCompletos(
                        e.arrastrarAContenedores
                    )}
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.ruleta)?.instrucciones}
                    />
                ) : null}
            </ActWrapper>:
            //Relacionar nuevas
            e.bloque._id==='61fb5f323f30e059b82c3dc2' && e.relacionarColumnas && e.relacionarColumnas !== '[]' ? 
            <ActWrapper modoPrevisualizar tipo={'relacionar'}>
                <ActRelacionarVisor
                    f1={filaUnoItems(JSON.parse(e.relacionarColumnas).relacionarColumnas)} 
                    f2={filaDosItems(JSON.parse(e.relacionarColumnas).relacionarColumnas)} 
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.relacionarColumnas).indicaciones}
                />
            </ActWrapper>:
            //Relacionar viejo
            e.bloque._id==='61fb5f323f30e059b82c3dc2' && e.actividad.columnas.length > 0 ? 
            <ActWrapper modoPrevisualizar tipo={'relacionar'}>
                <ActRelacionarVisor
                    f1={relacionarData(e.actividad.columnas).filter((item, index)=>(isEven(index))).map((el, i)=>({...el, orden:i}))} 
                    f2={relacionarData(e.actividad.columnas).filter((item, index)=>(isOdd(index))).map((el, i)=>({...el, orden:i}))} 
                    evaluable={modoProfesor}
                />
            </ActWrapper>:
            //Anagrama y ordenar
            e.bloque._id==='61fb5f338f30e059b82c4da2' && e.ruleta !== undefined && e.anagrama !== undefined ? 
            <ActWrapper modoPrevisualizar tipo={'ordenar'}>
                {JSON.parse(e.ruleta).tipoAct === 4 ?
                <EncontrarPalabraFaltante 
                    actData={JSON.parse(e.anagrama).sort((a,b)=>(a.orden > b.orden ? 1 : -1))} 
                    palabrasIncorrectas={JSON.parse(e.ruleta).palabrasIncorrectas} 
                    palabrasCorrectas={JSON.parse(e.ruleta).encontrarPalabras}
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.ruleta).indicaciones}
                />
                :
                <>
                    <ContentSwitcher 
                        data={JSON.parse(e.anagrama).sort((a,b)=>(a.orden > b.orden ? 1 : -1))}
                        evaluable={modoProfesor}
                        actValue={JSON.parse(e.ruleta).tipoAct}
                        instrucciones={JSON.parse(e.ruleta).indicaciones}
                    /> 
                </>
            }
            </ActWrapper>:
            //Evidencias
            e.bloque._id === '632250794cc52addd004a7a2' && e.actividad._id !== undefined && e.actividad.evidencia ?
            <div className='overflow-hidden'>
                <EvidenciaVisor
                    uma={e.bloque.uma}
                    bloque={e.bloque._id}
                    actividad={e.actividad._id}
                    evidencia={e.actividad.evidencia._id}
                    libro={e.contenido}
                    materia={''}
                    seguimientoEvidencia={''} 
                    alumno={null}
                    agrupador={''}
                    nombre={e?.actividad?.evidencia?.nombre}
                    descripcion={e?.actividad?.evidencia?.descripcion}
                    rubricas={getRubricas(e?.actividad?.evidencia)}
                />
            </div>:
            //Sopa de letras
            e.bloque._id === '633b670bf0b644b4af02099a' && e.sopaDeLetras !== '[]' ?
            <ActWrapper 
                modoPrevisualizar 
                tipo={'sopa'}
            >
                <SopaDeLetrasVisor  
                    dificultad={JSON.parse(e.sopaDeLetras).dificultad} 
                    actData={JSON.parse(e.sopaDeLetras).actData.length > 0 ? JSON.parse(e.sopaDeLetras)?.actData?.split(/\s+/).map((el)=>toCleanText(el))  : ['nimbus']} 
                    evaluable={modoProfesor}
                    instrucciones={JSON.parse(e.sopaDeLetras)?.instrucciones}
                />
            </ActWrapper>:
            //Hotspot y encontrar diferencias
            e.bloque._id === '6356bf8e7a25509de513edef' && e.hotspot !== '[]' ?
            <ActWrapper 
                modoPrevisualizar 
                tipo={JSON.parse(e.hotspot).tipo === 'HOTSPOT' ? 'hotspot' : JSON.parse(e.hotspot).tipo === 'HOTSPOT_MULTIPLE' ? 'hotspotMultiple' : 'encontrarDiferencias'}
            >
                {JSON.parse(e.hotspot).tipo === "HOTSPOT" ?
                <HotspotVisor hotspots={JSON.parse(e.hotspot).hotspots.filter((el)=>(el.type !== 'POLIGONO_TEMPORAL'))} img={JSON.parse(e.hotspot).imagen} evaluable={modoProfesor} instrucciones={JSON.parse(e.hotspot).instrucciones}/>:
                JSON.parse(e.hotspot).tipo === "DIFERENCIAS" ? 
                <EncontrarDiferenciasVisor hotspots={JSON.parse(e.hotspot).hotspots.filter((el)=>(el.type !== 'POLIGONO_TEMPORAL'))} img={JSON.parse(e.hotspot).imagen} img2={JSON.parse(e.hotspot).mirrorImagen} evaluable={modoProfesor} instrucciones={JSON.parse(e.hotspot).instrucciones}/>:
                JSON.parse(e.hotspot).tipo === "HOTSPOT_MULTIPLE" ? 
                <HotspotMultipleVisor hotspots={JSON.parse(e.hotspot).hotspots.filter((el)=>(el.type !== 'POLIGONO_TEMPORAL'))} img={JSON.parse(e.hotspot).imagen} evaluable={modoProfesor} instrucciones={JSON.parse(e.hotspot).instrucciones}/>:null}
            </ActWrapper>:
            //Herramienta Dibujo
            e.bloque._id === '6380fc2a8ebf880974f58bff' && e.dibujo !== '[]' ?
            <ActWrapper 
                modoPrevisualizar 
                tipo={'dibujo'}
            >
                <HerramientaDibujoVisor 
                    coloringPicture={JSON.parse(e.dibujo).imagen} 
                    setDownloadedImage={undefined} 
                    indicaciones={JSON.parse(e.dibujo).indicaciones} 
                    evaluable={modoProfesor}
                    canvasId={e._id}
                />
            </ActWrapper>:
            e.bloque._id === '6380fc2a8ebf880974f58bff' && e.dibujo !== '[]' ?
            <ActWrapper 
                modoPrevisualizar 
                tipo={'dibujo'}
            >
                <HerramientaDibujoVisor 
                    coloringPicture={JSON.parse(e.dibujo).imagen} 
                    setDownloadedImage={undefined} 
                    indicaciones={JSON.parse(e.dibujo).indicaciones} 
                    evaluable={modoProfesor}
                    key={new Date().getTime()}
                />
            </ActWrapper>:
            //Expresión oral
            e.bloque._id === '639d7a38ea251e2804ac5683' && e.habla !== undefined && e.habla !== "[]" ?
            <ActWrapper 
                modoPrevisualizar
                tipo={JSON.parse(e.habla).tipoAct === "PALABRAS" ? 'lecturaPalabras': JSON.parse(e.habla).tipoAct === "PARRAFO" ? 'lecturaVelocidad': JSON.parse(e.habla).tipoAct === "VELOCIDAD" ? 'lecturaPronunciacion':null}
                lang={JSON.parse(e.habla).lang}
            >
                <IsSpeakingCompatible>
                    {/* {JSON.parse(e.habla).tipoAct === "PALABRAS" ? <HerramientaHabla lang={JSON.parse(e.habla).lang} inputList={JSON.parse(e.habla).inputList} instrucciones={e?.texto}/>:
                    JSON.parse(e.habla).tipoAct === "PARRAFO" ? <HerramientaVelocidad lang={JSON.parse(e.habla).lang} inputList={JSON.parse(e.habla).inputList} instrucciones={e?.texto}/>:
                    JSON.parse(e.habla).tipoAct === "VELOCIDAD" ? <HerramientaPronunciacion lang={JSON.parse(e.habla).lang} inputList={JSON.parse(e.habla).inputList} instrucciones={e?.texto}/>:null} */}
                    <HerramientaPronunciacion lang={JSON.parse(e.habla).lang} inputList={JSON.parse(e.habla).inputList} instrucciones={e?.texto}/>
                </IsSpeakingCompatible>
            </ActWrapper>
            :
            //Texto a voz
            e.bloque._id === '639d79acea251e2804ac5680' && e.leeyescucha !== undefined && e.leeyescucha !== "[]" ?
            <ActWrapper 
                modoPrevisualizar
                tipo={'leeYEscucha'}
                lang={JSON.parse(e.leeyescucha).lang}
            >
                <LectorTextoMultiple
                    inputList={JSON.parse(e.leeyescucha).inputList}
                    speechSpeed={JSON.parse(e.leeyescucha).speechSpeed}
                    viewSourceText={JSON.parse(e.leeyescucha).viewSourceText}
                    lang={JSON.parse(e.leeyescucha).lang}
                    instrucciones={e?.texto}
                />
            </ActWrapper>
            :
            //Dictados
            e.bloque._id === '639d7aa3ea251e2804ac5686' && e.escuchayescribe !== undefined && e.escuchayescribe !== "[]" ?
            <ActWrapper 
                modoPrevisualizar
                tipo={'escuchaEscribe'}
                lang={JSON.parse(e.escuchayescribe).lang}
            >
                <EscuchaEscribe
                    viewSourceText={JSON.parse(e.escuchayescribe).viewText}
                    inputList={JSON.parse(e.escuchayescribe).inputList} 
                    lang={JSON.parse(e.escuchayescribe).lang}
                    instrucciones={e?.texto}
                />
            </ActWrapper>
            :
            //crucigrama
            e.bloque._id === '635029ccbc637d1d0280f9df' && e.crucigrama !== '[]' ?
            <ActWrapper 
                modoPrevisualizar 
                tipo={'crucigrama'}
            >
                <CrucigramaVisor
                    inputList={JSON.parse(e.crucigrama)}
                    evaluable={modoProfesor}
                    instrucciones={e?.texto}
                />
            </ActWrapper>
            :
            //contenedor de recursos
            e.bloque._id === '640a4d5cfd52527d8cf0e151' && e.contenedorDeRecursos && e.path ?
            <ContenedorArchivos archivo={e.path} namesArr={JSON.parse(e.contenedorDeRecursos)} instrucciones={e?.texto}/>
            :
            //Bloque HTML5
            e.bloque._id === '6410f983612c9ba30c0205e9' && e.html && e.html !== '' ?
            <>
                <BlockWrapper evaluable={false}>
                    <HTMLplayer interactivo={e.html} instrucciones={e?.texto}/>
                </BlockWrapper>
            </>
            :
            //Preguntas abiertas 
            e.bloque._id === '6537fd18db7c57b56c5df221' && e.html && e.html !== '' ?
            <BlockWrapper evaluable={false}>
                <PreguntasAbiertas 
                    preguntas={JSON.parse(e.html).inputList} 
                    instrucciones={JSON.parse(e.html)?.instrucciones}
                    tipoOrden={JSON.parse(e.html)?.tipoOrdenado}
                />
            </BlockWrapper>
            :
            //Autoevaluación
            e.bloque._id === '6549361146fe47f78130368f' && e.html && e.html !== '' ?
            <BlockWrapper evaluable={false}>
                <Autoevaluacion 
                    rubricas={JSON.parse(e.html).inputList}
                    tipo={JSON.parse(e.html).tipoEscala}
                    descripcion={JSON.parse(e.html).instrucciones}
                />
            </BlockWrapper>
            :
            //Test
            e.bloque._id === '6553d6388ea57bf0504be236' && e.html && e.html !== '' ?
            <BlockWrapper evaluable={false}>
                <Test
                    preguntas={JSON.parse(e.html).preguntas}
                    respuestas={JSON.parse(e.html).respuestas}
                    enunciados={JSON.parse(e.html).enunciados}
                    nombre={JSON.parse(e.html).nombre}
                    defaultMessage={JSON.parse(e.html).defaultMessage}
                    instrucciones={e?.texto}
                />
            </BlockWrapper>
            :
            //Tarjetas
            e.bloque._id === '6537fd18db7c57b56c272727' && e.html && e.html !== '' ?
            <Tarjetas tarjetas={JSON.parse(e.html).inputList} instrucciones={e?.texto}/>
            :
            //Modelos 3d
            e.bloque._id === '6553d6388ea57bf050242424' && e.html && e.html !== '' ?
            <FullScreen text={e?.pieDeFoto}>
                <ModelViewer file={e.html}/>
            </FullScreen>
            :
            //Comparador de img
            e.bloque._id === '61fb5f313f30e05947474747' && e.html && e.html !== '' ?
                <BlockWrapper parseText={false}>
                    <ImageComparator imagenUno={JSON.parse(e.html)?.imageOne} imagenDos={JSON.parse(e.html)?.imageTwo} instrucciones={e?.texto}/>
                </BlockWrapper>
            :
            //Clic and learn
            e.bloque._id === '61fb5f313f30e05919191919' && e.hotspot && e.hotspot !== '' ?
            <ClickAndLearn 
                img={JSON.parse(e.hotspot)?.imagen} 
                hotspots={JSON.parse(e.hotspot)?.hotspots} 
                instrucciones={e?.texto}
            />
            :
            null
        }
    </div>
  )
}

export default ModoVisor