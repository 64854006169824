import React, { useRef, useState, useEffect } from "react";
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { Trans } from "react-i18next";
import SimpleModal from "../../../fragments/Modals/SimpleModal";
import { toCleanText } from "./ActividadesUtils/ActividadesUtils";
import { useTranslation } from "react-i18next";

function CrucigramaBody({
  crucigrama,
  setCrossword,
  evaluable,
  crucigramaLength,
  umaBloque,
  guardarCalificacion,
}) {
  const [iniciado, setIniciado] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [wordInfo, setWordInfo] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const activityContext = useContext(ActividadContext);

  const modifyCrossword = () => {
    setIniciado(true);
    const newCrosswordBody = crucigrama.map((el) => {
      if (el._id === selectedWord) {
        return { ...el, myAnswer: inputValue };
      } else return el;
    });
    setCrossword(newCrosswordBody);
    toggleModal();
  };

  const calificar = () => {
    const puntosCorrectos = crucigrama.filter(
      (el) => toCleanText(el?.myAnswer) === toCleanText(el?.answer)
    )?.length;
    const puntosTotales = crucigrama.length;
    const calificacion = (puntosCorrectos / puntosTotales) * 100;
    if (iniciado) {
      activityContext.postCalificacionActividad(calificacion);
      if (umaBloque) {
        guardarCalificacion(umaBloque, puntosCorrectos, "");
      }
    } else {
      activityContext.postCalificacionActividad(null);
    }
  };
  const { innerWidth: width } = window;

  const largestx = [...crucigrama]
    .map((el) => ({
      x: el.startx + (el.orientation === "across" ? el.answer.length : 0),
    }))
    .sort((a, b) => (a.x > b.x ? 1 : -1))[crucigrama.length - 1].x;

  const largesty = [...crucigrama]
    .map((el) => ({
      ...el,
      y: el.starty + (el.orientation === "down" ? el.answer.length : 0),
    }))
    .sort((a, b) => (a.y > b.y ? 1 : -1))[crucigrama.length - 1].y;

  const cellWidth = (width > 968 ? 968 : width) / 2 / largestx;

  const selectWord = (info) => {
    setWordInfo(info);
    setSelectedWord(info?._id);
    if (info?.myAnswer) setInputValue(info.myAnswer);
    else setInputValue("");
  };

  const toggleModal = () => {
    setWordInfo(null);
  };

  const { t } = useTranslation();


  return (
    <>
      <SimpleModal isOpen={wordInfo !== null} toggleModal={toggleModal}>
        {wordInfo && (
          <div className="p-4">
            <div className="font-bold text-lightBlue mb-2">
              {"#" +
                wordInfo.position +
                ` ` +
                (wordInfo?.orientation === "across"
                  ? "Horizontal"
                  : "Vertical")}
            </div>
            <div className="mb-2 text-nimbusLight">- {wordInfo?.clue}</div>
            <div className="text-nimbusLight">
              - Esta palabra tiene {wordInfo?.answer?.length} letras
            </div>
            <input
              type="text"
              className="focus:border-none focus:outline-none w-full my-4 border bg-[#22bafb24] h-12 outline-none focus:ring-0 focus:border-transparent border-none rounded-md"
              placeholder={t('writeCrosswordWord')}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              autoFocus
              autoCorrect={false}
              spellCheck={false}
            />
            <div className="flex items-center justify-center">
              <button
                onClick={toggleModal}
                className="bg-white px-6 py-2 rounded-full text-lightBlue border border-lightbBlue mx-1"
              >
                <Trans i18nKey={'cancelLabel'}></Trans>
              </button>
              <button
                onClick={modifyCrossword}
                className="bg-lightBlue px-6 py-2 rounded-full text-white mx-1"
              >
                <Trans i18nKey={'addLabel'}></Trans>
              </button>
            </div>
          </div>
        )}
      </SimpleModal>
      <div className="flex justify-around">
        <div
          className="relative flex"
          style={{ width: largestx * cellWidth, height: largesty * cellWidth }}
        >
          {crucigrama.map((el) => (
            <div
              onClick={() => selectWord(el)}
              style={{
                top: cellWidth * el.starty,
                left: cellWidth * el.startx,
                width:
                  el.orientation === "across"
                    ? cellWidth * el.answer.length
                    : cellWidth,
                height:
                  el.orientation === "across"
                    ? cellWidth
                    : cellWidth * el.answer.length,
              }}
              className="absolute cursor-pointer bg-white"
            >
              <div className="ml-1 text-sm text-lightBlue z-[3] relative w-fit">
                {el?.position}
              </div>
              {el.answer.split("").map((character, i) => (
                <div
                  style={{
                    width: cellWidth,
                    height: cellWidth,
                    left: el.orientation === "across" ? cellWidth * i : 0,
                    top: el.orientation === "down" ? cellWidth * i : 0,
                    backgroundColor: selectedWord === el._id ? "#22bafb24" : "",
                    zIndex: selectedWord === el._id ? 2 : 1,
                  }}
                  className="border absolute flex items-center justify-center border-lightBlue custom-transition"
                >
                  {evaluable && (
                    <div className="z-[99]">{el?.myAnswer?.split("")[i]}</div>
                  )}
                  {!evaluable && character}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="text-nimbusLight ml-4 flex-1 ">
          <div className="w-fit mx-auto">
            <div className="font-bold mb-4 mt-10">Horizontal</div>
            {crucigrama
              .filter((el) => el.orientation === "across")
              .map((el) => (
                <div
                  onClick={() => selectWord(el)}
                  className={`cursor-pointer p-2 w-fit rounded-md custom-transition ${
                    selectedWord === el._id ? "bg-[#22bafb24]" : ""
                  }`}
                >
                  {`${el?.position}) ${el.clue}`}
                </div>
              ))}
            <div className="font-bold mb-4 mt-4">Vertical</div>
            {crucigrama
              .filter((el) => el.orientation === "down")
              .map((el) => (
                <div
                  onClick={() => selectWord(el)}
                  className={`cursor-pointer p-2 w-fit rounded-md custom-transition ${
                    selectedWord === el._id ? "bg-[#22bafb24]" : ""
                  }`}
                >
                  {`${el?.position}) ${el.clue}`}
                </div>
              ))}
          </div>
        </div>
      </div>
      {evaluable ? (
        <button
          className="className='mt-4 md:mt-[4rem] border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit'"
          onClick={() => calificar()}
        >
          <Trans>{iniciado ? "Calificar" : "Cerrar"}</Trans>
        </button>
      ) : null}
    </>
  );
}

export default CrucigramaBody;
