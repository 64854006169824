import React, {useState, useEffect, useRef } from "react";
import { textWithoutSpaces } from "./ActividadesUtils/ActividadesUtils";
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import AnagramWord from "./AnagramWord";
import { useContext } from "react";
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import CerrarActButton from "../ActividadesUtils/CerrarActButton";
import { Trans } from "react-i18next";

const ContentSwitcher = ({ data, evaluable, actValue, guardarCalificacion, umaBloque, instrucciones }) => {
  const activityContext = useContext(ActividadContext)
  const [location, setLocation ] = useState(0)
  const [puntuacion, setPuntuacion] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [iniciado, setIniciado] = useState(false)

  const handleNextItem = (e) => {
    if(location + 1 < data.length){
      setLocation(location + 1)
    }
  };

  const handlePreviousItem = () => {
    if(location !== 0){
      setLocation(location - 1)
    }
  };

  const addPoint = () =>{
    setPuntuacion(prev => {
      return prev + 1
    });
  }

  const getTotalWords = () =>{
    if(actValue === 1){
      //En caso de actividad anagrama
      return data.map((item)=>(textWithoutSpaces(item.texto).split(' ').length)).reduce((partialSum, a) => partialSum + a, 0);
    }else if(actValue === 2){
      //En casdo de ordenar palabras
      return data.length
    }else{
      //En caso de ordenar por pasos
      return 1
    }
  }

  const calificacion = (puntuacion / getTotalWords()) * 100

  const calificar = () =>{
    if(umaBloque)guardarCalificacion(umaBloque, puntuacion, '')
    activityContext.postCalificacionActividad(iniciado ? calificacion : null)
  }

  const GetComponentWidth = ({children}) => {
    const ref = useRef(null);
    useEffect(() => {
      if(ref.current.offsetWidth !== 0 ){
        setContainerWidth(ref.current.offsetWidth)
      }
    }, [ref.current]);
    return <div ref={ref} className="w-full max-w-activity">{children}</div>;
  };

  const enableScore = () =>{
    if(!iniciado)setIniciado(true)
  }

  return (
    <>
      {instrucciones && <div className={'max-w-activity act-instructions mb-4'}>{instrucciones}</div>}        
      <GetComponentWidth/>
      <div className="my-14 max-w-activity w-full">
          {data.map((item, index) => (
            <>
            <div className={location == index ? "block text-center" : 'hidden' } key={index}>
              {/* Anagrama */}
              {actValue === 1 ? 
              textWithoutSpaces(item.texto).split(" ").map((el)=>(
                  <AnagramWord 
                    word={el} 
                    addPoint={addPoint} 
                    evaluable={evaluable} 
                    containerWidth={containerWidth}
                    actValue={actValue}
                    enableScore={enableScore}
                  />
              ))
              :null}
               {/* Ordenar Palabras */}
               {actValue === 2? 
               [textWithoutSpaces(item.texto)].map((el)=>(
                  <AnagramWord 
                    word={el} 
                    addPoint={addPoint} 
                    evaluable={evaluable} 
                    containerWidth={containerWidth}
                    actValue={actValue}
                    enableScore={enableScore}
                  />
              )):null}
            </div>
            </>
          ))}
          {/* Ordenar por pasos */}
          {actValue === 3? 
            <>
            <AnagramWord 
              word={data.map((el)=>({texto: el.texto, imagen:el.imagen, audio:el.audio, video:el.video}))} 
              addPoint={addPoint} 
              evaluable={evaluable} 
              containerWidth={containerWidth}
              actValue={actValue}
              enableScore={enableScore}
            />
            </>:null
          }
          {actValue !== 3 ? 
          <div className="flex w-full justify-center items-center py-4">
            <div className="bg-black text-white p-2 md:p-3 rounded h-min cursor-pointer" onClick={handlePreviousItem}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </div>
            <div className="p-2 md:p-4">
              {location + 1}
              <div className="mx-2 inline-block">
                <Trans i18nKey='slideOf'></Trans>
              </div>
              {data.length}
            </div>
            <div className="bg-black text-white p-2 md:p-3 rounded h-min cursor-pointer" onClick={handleNextItem}>
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </div>
          </div>:null
          }
      </div>
      <CerrarActButton onClick={calificar} text={iniciado ? 'Calificar' : 'Cerrar'}/>
    </>
  );
};

ContentSwitcher.defaultProps = {
  evaluable:true,
  data:[]
}

export default ContentSwitcher;
