import { ReactComponent as Delete } from "../../../../assets/icons/remove.svg";
import { toast } from "react-toastify";
import { useListaDifusionDetalles } from "./ListaDifusionDetallesContext";
import alertConfiguration from "../../../utils/alertsUtils";
import { getRolLabel } from "../../../utils/rolesUtils";

const UsersAddedRow = ({ user }) => {
  const { selectUser, usersAdded, setUsersAddedFiltered, setUsersAdded, ver } =
    useListaDifusionDetalles();
  const { esSeleccionado, usuario, estructura } = user;

  const fullName = `${usuario?.nombres} ${usuario?.primerApellido} ${usuario?.segundoApellido}`;

  const handleRemoveUser = (_id) => {
    // Remover el usuario de usersAdded
    setUsersAdded((users) => {
      const userIndex = users.findIndex((user) => user.usuario._id === _id);
      if (userIndex !== -1) {
        const userToRemove = users[userIndex];
        // Añadir el usuario removido a globalUsers
        usersAdded.push(userToRemove);
        // Remover el usuario de usersAdded
        const newUsers = users.filter((user) => user.usuario._id !== _id);
        const unselectNewUsers = newUsers.map((user) => {
          return { ...user, esSeleccionado: false };
        });
        return unselectNewUsers;
      }
      return users;
    });

    // Actualizar destinatariosFiltro con globalUsers
    setUsersAddedFiltered([...usersAdded]);

    toast.success(
      "El usuario se ha removido de la lista exitosamente.",
      alertConfiguration
    );
  };

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-lg border-r-0 font-sans text-center">
        <div className="rounded-md flex flex-row items-center justify-center mr-14 gap-2">
          {!ver && (
            <input
              type="checkbox"
              name={`selectUser`}
              id={`selectUser`}
              className="rounded-md border-2 border-gray-300 my-0 cursor-pointer"
              checked={esSeleccionado ?? false}
              onChange={() => selectUser(user)}
            />
          )}
        </div>
      </td>
      <td className="border-y-2 font-sans text-left">{fullName}</td>
      <td className="border-y-2 font-sans text-center">
        {usuario?.correoElectronico}
      </td>
      <td className="border-y-2 font-sans text-center">{estructura}</td>
      <td className="border-y-2 font-sans text-center">
        {usuario?.roles?.map((rol) => getRolLabel(rol)).join(", ")}
      </td>
      <td className="border-2 rounded-r-lg border-l-0 font-sans pb-1 pt-3 text-center">
        {!ver && (
          <button
            title="Remover destinatario"
            onClick={() => handleRemoveUser(usuario._id)}
          >
            <Delete className="w-5 h-5 mr-2" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default UsersAddedRow;
