import { markAvisoAsRead } from "../../../services/Api/historial-avisos";
import { toast } from "react-toastify";
import { useState } from "react";
import alertConfiguration from "../../../utils/alertsUtils";
import Aviso from "./Aviso";
import DeleteAviso from "./DeleteAviso";
import EliminarIcon from "../../../../assets/icons/trash2.svg";
import MarkAsReadIcon from "../../../../assets/icons/mark-as-read.svg";
import NimbusModal from "../../../fragments/NimbusModal";
import StatusIcon from "./StatusIcon";
import VisualizarIcon from "../../../../assets/icons/viewIcon.svg";

const customStyles = {
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(120, 120, 120, 0.75)",
  },
};

const formatDate = (dateISO) => {
  if (!dateISO) return;
  const date = new Date(dateISO);

  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };

  const formateador = new Intl.DateTimeFormat("es-ES", options);
  return formateador?.format(date);
};

const HistorialAvisosRow = ({ aviso, getAvisos }) => {
  const [view, setView] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleMarkAsRead = async () => {
    try {
      const body = {
        _id: aviso?._id,
        esLeido: !aviso?.esLeido,
      };

      await markAvisoAsRead(body);
      toast.success(
        aviso?.esLeido
          ? "El aviso se marcó como no leído. El cambio en el indicador en el icono de campana será visible en 3 minutos"
          : "El aviso se marcó como leído. El cambio en el indicador en el icono de campana será visible en 3 minutos",
        alertConfiguration
      );
      getAvisos(); // Reload avisos
    } catch (error) {
      console.error(error);
      toast.error(
        aviso?.esLeido
          ? "Ocurrió un error al marcar como no leído este aviso."
          : "Ocurrió un error al marcar como leído este aviso.",
        alertConfiguration
      );
    }
  };

  return (
    <>
      <tr className="p-2 text-left bg-white">
        {/* Status icon */}
        <td className="border-y-2 font-sans text-center">
          <div className="rounded-md flex flex-row items-center justify-center gap-4">
            <StatusIcon estatus={aviso?.esLeido} />
          </div>
        </td>

        {/* Titile */}
        <td className="border-y-2 font-sans text-left">
          {aviso?.aviso?.nombre}
        </td>

        {/* Delivered date */}
        <td className="border-y-2 font-sans text-center">
          {formatDate(aviso?.fechaRecibido)}
        </td>

        {/* Read date */}
        <td className="border-y-2 font-sans text-center">
          {aviso?.esLeido ? formatDate(aviso?.fechaLeido) : "-"}
        </td>

        {/* Category */}
        <td className="border-y-2 font-sans text-center">
          {aviso?.aviso?.categoria?.nombre}
        </td>

        {/* Status */}
        <td className="border-y-2 font-sans text-center">
          {aviso?.esLeido ? "Leído" : "Sin leer"}
        </td>

        {/* Action */}
        <td className="border-2 rounded-r-md border-l-0">
          <div className="flex items-center justify-around">
            {/* Mark as read */}
            <button
              title="Marcar como no leído"
              className="w-5 flex"
              onClick={handleMarkAsRead}
              disabled={!aviso?.esLeido}
            >
              {aviso?.esLeido && (
                <img src={MarkAsReadIcon} className="w-4 h-4" />
              )}
            </button>

            {/* View */}
            <button
              title="Ver aviso"
              className="w-5 flex"
              onClick={() => setView(true)}
            >
              <img src={VisualizarIcon} className="w-5 h-5" />
            </button>

            {/* Delete */}
            <button
              title="Eliminar aviso"
              className="w-5 flex"
              onClick={() => setDeleteModal(true)}
            >
              <img src={EliminarIcon} className="w-5 h-5" />
            </button>
          </div>
        </td>
      </tr>

      {/* Delete Modal */}
      <NimbusModal isOpen={deleteModal} styles={customStyles} showLogo>
        <DeleteAviso
          onCloseModal={() => setDeleteModal(false)}
          id={aviso?._id}
          getAvisos={getAvisos}
        />
      </NimbusModal>

      {/* View Modal */}
      <NimbusModal isOpen={view} styles={customStyles} showLogo={false}>
        <Aviso
          onCloseModal={() => setView(false)}
          aviso={aviso}
          markAsRead={handleMarkAsRead}
        />
      </NimbusModal>
    </>
  );
};

export default HistorialAvisosRow;
