import { useGestorAvisos } from "../GestorAvisosContext";
import GestorAvisosFilterForm from "./GestorAvisosFilterForm";

const GestorAvisosFilter = ({ getAvisos, currentPage }) => {
  const { avisosFiltro, itemsPerPage } = useGestorAvisos();

  const totalItemsUntilNow = Math.min(
    currentPage * itemsPerPage,
    avisosFiltro?.length
  );

  return (
    <div className="w-full lg:flex lg:flex-wrap lg:justify-between space-y-5 lg:space-y-0 py-6 items-start">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {totalItemsUntilNow} / {avisosFiltro?.length}
          </p>
          <p className="text-black">Avisos</p>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2">
        <GestorAvisosFilterForm getAvisos={getAvisos} />
      </div>
    </div>
  );
};

export default GestorAvisosFilter;
