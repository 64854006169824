export const getRolLabel = (rol) => {
  switch (rol) {
    case "DIR_PLANTEL":
      return "Director de plantel";
    case "COOR_ACADEMICO":
      return "Coordinador académico";
    case "ADMIN_OPERATIVO":
      return "Administrador operativo";
    case "ADMIN":
      return "Administrador";
    case "PROFESOR":
      return "Profesor";
    case "ALUMNO":
      return "Alumno";
    case "INVITADO":
      return "Invitado";
    case "PADRE_TUTOR":
      return "Padre/madre/tutor";
    case "DIR_CORPORATIVO":
      return "Director corporativo";
    case "AUTOR_INDEPENDIENTE":
      return "Autor independiente";
    case "DISTRIBUIDOR":
      return "Distribuidor";
    case "DIR_EDITORIAL":
      return "Director editorial";
    case "ASESOR":
      return "Asesor";
    case "EDITOR_EDITORIAL":
      return "Editor editorial";
    case "AUTOR_EDITORIAL":
      return "Armador editorial";
    case "AUTOR_ESCOLAR":
      return "Armador escolar";
    case "EDITOR_ESCOLAR":
      return "Editor escolar";
    default:
      break;
  }
};

export const rolesEditorial = ["DIR_EDITORIAL", "EDITOR_EDITORIAL", "AUTOR_EDITORIAL", "AUTOR_INDEPENDIENTE"];
export const rolesDistribucion = ["DISTRIBUIDOR", "ASESOR"];
export const rolesCorporativo = ["DIR_CORPORATIVO"];
export const rolesPlantel = [
  "DIR_PLANTEL",
  "ADMIN_OPERATIVO",
  "COOR_ACADEMICO",
  "EDITOR_ESCOLAR",
  "AUTOR_ESCOLAR",
  "PROFESOR",
  "PADRE_TUTOR",
  "ALUMNO",
];

export const getFullUsername = (usuario) => {
  return (
    usuario?.nombres +
    " " +
    usuario?.primerApellido +
    " " +
    usuario?.segundoApellido
  );
};