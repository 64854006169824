import {
  HistorialAvisosContextProvider,
  useHistorialAvisos,
} from "./HistorialAvisosContext";
import { getHistorialAvisos } from "../../services/Api/historial-avisos";
import { MESSAGES } from "../../utils/messagesUtils";
import { toast } from "react-toastify";
import { usePagination } from "../../hooks/usePagination";
import { UserContext } from "../../services/user";
import alertConfiguration from "../../utils/alertsUtils";
import HistorialAvisosFilter from "./fragments/HistorialAvisosFilter";
import HistorialAvisosTable from "./fragments/HistorialAvisosTable";
import LoadingScreen from "../../fragments/LoadingScreen";
import NoDataMessage from "../../fragments/NoDataMessage";
import React, { useContext, useEffect, useState } from "react";
import SubHeaderHistorialAvisos from "./fragments/SubHeaderHistorialAvisos";
import TablePagination from "../../fragments/TablePagination";
import withUserAccesability from "../../hoc/withUserAccesability";

const itemsPerPage = 10;

const HistorialAvisosHome = ({ isUserAllowed }) => {
  return (
    <HistorialAvisosContextProvider>
      <HistorialAvisosHomeComponents isUserAllowed={isUserAllowed} />
    </HistorialAvisosContextProvider>
  );
};

const HistorialAvisosHomeComponents = ({ isUserAllowed }) => {
  const { avisosFiltro, setAvisos, setAvisosFiltro } = useHistorialAvisos();
  const { user, activeRole } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    avisosFiltro,
    itemsPerPage
  );

  useEffect(() => {
    if (activeRole !== "") {
      getAvisos();
    }
  }, [activeRole]);

  useEffect(() => {
    // Establece el intervalo para llamar a getAvisos cada 10 minutos (600,000 ms)
    const interval = setInterval(() => {
      getAvisos();
    }, 180000); // 180,000 ms = 3 minutos

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, []);

  const getAvisos = async () => {
    try {
      const avisosResp = await getHistorialAvisos(user._id);
      const sortedAvisos = avisosResp.sort(
        (a, b) => new Date(b.fechaRecibido) - new Date(a.fechaRecibido)
      );
      setAvisos(sortedAvisos);
      setAvisosFiltro(sortedAvisos);
    } catch (error) {
      console.error(error);
      toast.error(error.message, alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderHistorialAvisos />
          <div
            className={`${
              avisosFiltro?.length !== 0 && "justify-between"
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <HistorialAvisosFilter
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
              />
              <HistorialAvisosTable
                getAvisos={getAvisos}
                currentData={currentData()}
              />
            </div>
            <div>
              {avisosFiltro?.length === 0 ? (
                <NoDataMessage message={MESSAGES.MESSAGES_HISTORIAL_AVISOS} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withUserAccesability(HistorialAvisosHome);
