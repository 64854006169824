import { ReporteAvisoFilterForm } from "./ReporteAvisoFilterForm";

const ReporteAvisosFilter = ({
  filtro,
  setFiltro,
  total,
  itemsPerPage,
  currentPage,
}) => {
  const totalItemsUntilNow = Math.min(currentPage * itemsPerPage, total);

  return (
    <div className="w-full md:flex md:flex-wrap md:justify-between space-y-5 md:space-y-0 py-6 items-center">
      <div className="flex gap-x-5">
        <div className="flex flex-wrap items-center">
          <p className="mr-3 font-bold text-nimbus border-y-4 border-cyan-400 rounded-lg px-3 flex items-center justify-center">
            {totalItemsUntilNow} / {total}
          </p>
          <p className="text-black">Reportes</p>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-2 w-full md:w-auto">
        <ReporteAvisoFilterForm filtro={filtro} setFiltro={setFiltro} />
      </div>
    </div>
  );
};

export default ReporteAvisosFilter;
