import { getListasDifusionPropias } from "../../services/Api/ListasDifusion";
import { MESSAGES } from "../../utils/messagesUtils";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { usePagination } from "../../hooks/usePagination";
import { UserContext } from "../../services/user";
import alertConfiguration from "../../utils/alertsUtils";
import ListasDifusionFilter from "./fragments/ListasDifusionFilter";
import ListasDifusionTable from "./fragments/ListasDifusionTable";
import LoadingScreen from "../../fragments/LoadingScreen";
import NoDataMessage from "../../fragments/NoDataMessage";
import SubHeaderListasDifusion from "./fragments/SubHeaderListasDifusion";
import TablePagination from "../../fragments/TablePagination";
import withUserAccesability from "../../hoc/withUserAccesability";
import { useDispatch } from "react-redux";
import { limpiarAviso } from "../../../reducers/avisoData/avisoDataAction";

const itemsPerPage = 10;

const ListasDeDifusionHome = ({ isUserAllowed }) => {
  const { user, activeRole } = useContext(UserContext);
  const [listas, setListas] = useState([]);
  const [listasFiltro, setListasFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    listasFiltro,
    itemsPerPage
  );

  const getListasDifusion = async () => {
    try {
      setLoading(true);
      if (activeRole !== "") {
        let { registros } = await getListasDifusionPropias(
          user._id,
          currentPage
        );
        registros.sort((listaDifusionA, listaDifusionB) =>
          listaDifusionB.fechaCreacion.localeCompare(
            listaDifusionA.fechaCreacion
          )
        );
        setListas(registros);
        setListasFiltro(registros);
      }
    } catch (error) {
      console.log(error);
      toast.error("Ha ocurrido un error.", alertConfiguration);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeRole !== "") {
      getListasDifusion();
    }
    dispatch(limpiarAviso());
  }, [activeRole]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderListasDifusion />
          <div
            className={`${
              listasFiltro?.length !== 0 && "justify-between"
            } relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <ListasDifusionFilter
                listas={listas}
                listasFiltro={listasFiltro}
                setListasFiltro={setListasFiltro}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
              />
              <ListasDifusionTable
                listas={currentData()}
                getListasDifusion={getListasDifusion}
              />
            </div>
            <div>
              {listasFiltro?.length === 0 ? (
                <NoDataMessage
                  message={MESSAGES.MESSAGES_LISTAS_DIFUSION_TABLE}
                />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withUserAccesability(ListasDeDifusionHome);
