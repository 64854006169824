import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

const HerramientaLectorTexto = ({
  value,
  viewSourceText,
  paused,
  listening,
  finished,
  speakfnc,
  resumefnc,
  restartfnc,
  pausefnc,
  isPlaying,
}) => {
  return (
    <div className="parent my-5 w-[758px]">
      <div className="btnparent flex items-center justify-between">
        {viewSourceText ? (
          <div className="max-w-activity flex flex-col mx-5 flex-1">
            <div
              className={`block custom-transition md:text-xl whitespace-pre-wrap text-justify px-4`}
            >
              {value.texto.split(/\s+/).map((el) => (
                <div
                  onClick={() => speakfnc(el, "")}
                  className="inline-block mr-2 hover:font-bold cursor-pointer"
                >
                  {el}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="w-fit mx-auto">
          {isPlaying && (
            <>
              {paused ? (
                <button
                  className="bg-[#1c7ccc] text-white rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base w-12 h-12"
                  onClick={resumefnc}
                >
                  <FontAwesomeIcon
                    className="text-lg text-white"
                    icon={faPlay}
                  ></FontAwesomeIcon>
                </button>
              ) : (
                <button
                  className="bg-[#1c7ccc] text-white rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base w-12 h-12"
                  onClick={pausefnc}
                >
                  <FontAwesomeIcon
                    className="text-lg text-white"
                    icon={faPause}
                  ></FontAwesomeIcon>
                </button>
              )}
            </>
          )}

          <button
            className="bg-[#1c7ccc] text-white rounded-full mx-1 transition hover:brightness-125 text-sm md:text-base w-12 h-12"
            onClick={() => speakfnc(value.texto, value._id)}
          >
            <FontAwesomeIcon
              className="text-lg text-white"
              icon={isPlaying ? faRotateRight : faVolumeHigh}
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HerramientaLectorTexto;
