import { useContext, useEffect, useState } from "react";
import AvisoForm from "./AvisoForm";
import SubHeaderAvisosForm from "./SubHeaderAvisosForm";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../services/user";
import {
  GestorAvisosContextProvider,
  useGestorAvisosDetalles,
} from "./GestorAvisosDetallesContext";
import { getAviso } from "../../../services/Api/GestorAvisos";
import useValidateForm from "../../../hooks/useValidateForm";
import { useDispatch, useSelector } from "react-redux";
import { limpiarAviso } from "../../../../reducers/avisoData/avisoDataAction";
import moment from "moment-timezone";
import { obtenerUserEstructuraInfo } from "../../../utils/userUtils";
import { selectProgress } from "../../../../reducers/avisoData/avisoDataSelector";

const GestorAvisosDetalles = ({ editar, ver }) => {
  return (
    <GestorAvisosContextProvider>
      <GestorAvisosDetallesComponents editar={editar} ver={ver} />
    </GestorAvisosContextProvider>
  );
};

const GestorAvisosDetallesComponents = ({ editar, ver }) => {
  const params = useParams();
  const { user, activeRole } = useContext(UserContext);
  let horario = {};
  const savedDraft = useSelector(selectProgress);

  const [loading, setLoading] = useState(true);

  const { setVer, setEditar, setCount, count, setDraft } =
    useGestorAvisosDetalles();

  const { setValues } = useValidateForm({
    nombreAviso: "",
  });

  const dispatch = useDispatch();

  const getAvisoById = async () => {
    try {
      let avisoRes = await getAviso(params.avisoID);
      setValues({ nombreAviso: avisoRes?.nombre });
      const contenidoStore = avisoRes?.contenidos?.map((contenido) => {
        if (count < contenido?.orden) setCount(contenido?.orden + 1);
        let newContenido = {
          _id: contenido?._id,
          bloque: { _id: contenido?.bloque?._id },
          orden: contenido?.orden,
        };
        if (contenido.hasOwnProperty("path"))
          newContenido = { ...newContenido, path: contenido?.path };
        if (contenido.hasOwnProperty("texto"))
          newContenido = { ...newContenido, texto: contenido?.texto };
        if (contenido.hasOwnProperty("pieDeFoto"))
          newContenido = {
            ...newContenido,
            pieDeFoto: contenido?.pieDeFoto,
          };

        return newContenido;
      });

      const destinatariosStore = avisoRes?.destinatarios?.map(
        (destinatario) => {
          const estructuraKey = destinatario?.estructura?.toLowerCase();
          return {
            _id: destinatario?._id,
            esEliminado: destinatario?.esEliminado,
            esLeido: destinatario?.esLeido,
            usuario: {
              _id: destinatario?.usuario?._id,
            },
            estructura: destinatario?.estructura,
            [estructuraKey]: {
              _id: destinatario[estructuraKey]?._id,
            },
            listaDifusion: [destinatario?.listaDifusion?._id],
          };
        }
      );

      let newAviso = {
        _id: avisoRes?._id,
        visibilidad: avisoRes?.visibilidad,
        nombre: avisoRes?.nombre,
        categoria: avisoRes?.categoria?._id,
        descripcion: avisoRes?.descripcion,
        isUploadingFile: false,
        contenido: contenidoStore,
        destinatarios: destinatariosStore,
        calendario: {
          selected: avisoRes?.calendario,
          fechaInicio: null,
          fechaFin: null,
        },
        envio: {
          defaultOption: "Now",
          date: null,
          time: null,
        },
        saveProgress: savedDraft,
      };
      if (avisoRes?.calendario) {
        newAviso = {
          ...newAviso,
          calendario: {
            ...newAviso?.calendario,
            fechaInicio: avisoRes?.fechaInicio,
            fechaFin: avisoRes?.fechaFin,
          },
        };
      }
      if (avisoRes?.estatus === "PROGRAMADO") {
        newAviso = {
          ...newAviso,
          envio: {
            defaultOption: "Later",
            date: moment(avisoRes?.fechaEnvio)
              ?.tz(horario ? horario?.value : "America/Mexico_City")
              ?.format("YYYY-MM-DD"),
            time: moment(avisoRes?.fechaEnvio)
              ?.tz(horario ? horario?.value : "America/Mexico_City")
              ?.format("HH:mm"),
          },
        };
      } else if (avisoRes?.estatus === "ENVIADO") {
        setDraft(false);
      }
      dispatch(limpiarAviso(newAviso));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editar || ver) {
      getAvisoById();
      setVer(ver);
      setEditar(editar);
      if (activeRole !== "") {
        const { tipoEstructuraRequest: estructuraKey } =
          obtenerUserEstructuraInfo(user, activeRole);
        horario =
          estructuraKey === "plantel"
            ? user?.escuela?.horario
            : user[estructuraKey]?.horario;
      }
    }
  }, [activeRole, params]);

  return (
    <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
      <SubHeaderAvisosForm />
      <AvisoForm />
    </div>
  );
};

export default GestorAvisosDetalles;
