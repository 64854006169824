import { useEffect, useState } from "react";
import { formatPostDate, formatTime } from "../../../utils/dateFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  setCalendario,
  setFechaFin,
  setFechaInicio,
} from "../../../../reducers/avisoData/avisoDataAction";
import ReactSwitch from "react-switch";
import { useGestorAvisosDetalles } from "./GestorAvisosDetallesContext";
import { selectCalendar } from "../../../../reducers/avisoData/avisoDataSelector";

const CalendarForm = () => {
  const { editandoTexto, ver, editar } = useGestorAvisosDetalles();
  const { selected, fechaInicio, fechaFin } = useSelector(selectCalendar);

  const [isChecked, setIsChecked] = useState(false);

  const [dateFieldType, setDateFieldType] = useState("text");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [hourFieldType, setHourFieldType] = useState("text");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  const dispatch = useDispatch();

  const handleChangeToggle = (newValue) => {
    setIsChecked(newValue);
    dispatch(setCalendario(newValue));
    dispatch(setFechaInicio(null));
    dispatch(setFechaFin(null));
    setStartDate("");
    setStartHour("");
    setEndDate("");
    setEndHour("");
  };

  useEffect(() => {
    if (startDate !== "" && startHour !== "") {
      dispatch(setFechaInicio(new Date(startDate + " " + startHour)));
    }
    if (endDate !== "" && endHour !== "") {
      if (
        new Date(startDate + " " + startHour).getTime() <=
        new Date(endDate + " " + endHour).getTime()
      ) {
        dispatch(setFechaFin(new Date(endDate + " " + endHour)));
      } else {
        dispatch(setFechaFin(null));
      }
    }
  }, [startDate, startHour, endDate, endHour]);

  useEffect(() => {
    setIsChecked(selected);
    if (selected === true) {
      if (fechaInicio && fechaFin) {
        const date1 = new Date(fechaInicio);
        setStartDate(formatPostDate(date1));
        setStartHour(formatTime(date1));

        const date2 = new Date(fechaFin);
        setEndDate(formatPostDate(date2));
        setEndHour(formatTime(date2));
      }
    }
  }, [selected]);

  useEffect(() => {
    if (startDate && endDate) {
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        setEndDate("");
        setEndHour("");
        dispatch(setFechaFin(null));
      }
    }
  }, [startDate]);

  return (
    <>
      <div className="grid col-span-12">
        <div className="flex gap-4">
          <ReactSwitch
            onChange={() => handleChangeToggle(!isChecked)}
            id={"calendar_switch"}
            height={20}
            width={40}
            checked={isChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#18BAFB"
            disabled={editandoTexto || ver}
          />
          <span className="font-semibold text-base text-[#0D0C22] mb-2">
            Calendario
          </span>
        </div>
      </div>

      {isChecked && (
        <>
          <div className="flex col-span-6 flex-col">
            <CalendarInput
              dateFieldType={dateFieldType}
              setDateFieldType={setDateFieldType}
              label="inicio"
              dateValue={startDate}
              setDate={setStartDate}
              hourFieldType={hourFieldType}
              setHourFieldType={setHourFieldType}
              hourValue={startHour}
              setHour={setStartHour}
              disabledProp={ver || editandoTexto}
            />
            <div className="grid grid-cols-6">
              <div className="grid col-span-4">
                {!startDate && (
                  <p className="font-thin text-xs text-red-400">
                    {`La fecha de inicio es requerida`}
                  </p>
                )}
              </div>
              <div className="grid col-span-2">
                {!startHour && (
                  <p className="font-thin text-xs text-red-400">
                    {`La hora de inicio es requerida`}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex col-span-6 flex-col">
            <CalendarInput
              dateFieldType={dateFieldType}
              setDateFieldType={setDateFieldType}
              label="fin"
              dateValue={endDate}
              setDate={setEndDate}
              hourFieldType={hourFieldType}
              setHourFieldType={setHourFieldType}
              hourValue={endHour}
              setHour={setEndHour}
              dateDisabled={startDate}
              hourDisabled={startHour}
              disabledProp={ver || editandoTexto}
            />
            <div className="grid grid-cols-6">
              <div className="grid col-span-4">
                {startDate && !endDate && (
                  <p className="font-thin text-xs text-red-400">
                    {`La fecha de fin es requerida`}
                  </p>
                )}
              </div>
              <div className="grid col-span-2">
                {startHour && !endHour && (
                  <p className="font-thin text-xs text-red-400">
                    {`La hora de fin es requerida`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const CalendarInput = ({
  dateFieldType,
  setDateFieldType,
  label,
  dateValue,
  setDate,
  hourFieldType,
  setHourFieldType,
  hourValue,
  setHour,
  dateDisabled,
  hourDisabled,
  disabledProp,
}) => {
  return (
    <div className="grid col-span-6">
      <label className="font-semibold text-base text-[#0D0C22] mb-2">
        Fecha y hora de {label}
      </label>
      <div className="grid grid-cols-6">
        <div className="grid col-span-4">
          <input
            type={dateFieldType}
            name={`${label}Fecha`}
            id={`${label}Fecha`}
            placeholder="dd-mm-aaaa"
            onFocus={() => setDateFieldType("date")}
            onBlur={() => setDateFieldType("text")}
            min={label === "fin" ? dateDisabled : undefined}
            value={dateValue}
            onChange={(e) => setDate(e.target.value)}
            className={`rounded-l-lg p-2 w-full border-divider border text-black/60 disabled:bg-[#EEEEEE] ${
              dateValue !== "" ||
              (label === "fin" && dateValue === "" && dateDisabled === "")
                ? "border-gray-300"
                : "border-red-400"
            }`}
            disabled={dateDisabled === "" || disabledProp}
          />
        </div>
        <div className="grid col-span-2">
          <input
            type={hourFieldType}
            name={`${label}Hora`}
            id={`${label}Hora`}
            placeholder="hh:mm"
            onFocus={() => setHourFieldType("time")}
            onBlur={() => setHourFieldType("text")}
            value={hourValue}
            onChange={(e) => setHour(e.target.value)}
            className={`rounded-r-lg p-2 w-full border-divider border text-black/60 disabled:bg-[#EEEEEE] ${
              hourValue !== "" ||
              (label === "fin" && hourValue === "" && hourDisabled === "")
                ? "border-gray-300"
                : "border-red-400"
            }`}
            disabled={hourDisabled === "" || disabledProp}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarForm;
