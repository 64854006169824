import {useState, useContext, useEffect} from 'react'
import { ActividadContext } from "./ActividadesUtils/ActWrapper";
import { colors } from "./ActividadesUtils/ActividadesUtils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {v4 as uuidv4} from 'uuid'
import { Trans } from 'react-i18next';
import ImagenValidada from './ActividadesUtils/RecursosValidados/ImagenValidada';
import SimplePlayer from './ActividadesUtils/SimplePlayer';

function HotspotMultiple({img, hotspots, evaluable, umaBloque, guardarCalificacion, instrucciones}) {
  const contenedorItems = hotspots.map((el, index)=>({
    ...el,
    items:hotspots.filter((item)=>(item._id === el._id)).map((item)=>({...item, arrastrable:true,background:colors[index] + '99'})),
    orden:index,
    _id:uuidv4(),
    columnId:index
  }))

  const contendoresVacios = hotspots.map((el, index)=>({
    ...el,
    items:[],
    orden:index,
    respuestaId:el._id,
    evaluable,
    _id:uuidv4(),
    columnId:index + hotspots.length
  }))

  const currentColumns = [...contenedorItems,...contendoresVacios]
  const [columns, setColumns] = useState(currentColumns)
  const [iniciado, setIniciado] = useState(false)
  const activityContext = useContext(ActividadContext)

  const calificar = () =>{
    const columnasEvaluables = Object.entries(columns).filter((el)=>(el[1].evaluable && el[1].items.length))
    const itemsCorrectos = columnasEvaluables.filter((el)=>(el[1].respuestaId === el[1].items[0]._id))
    const calificacion = itemsCorrectos.length / hotspots.length * 100
    if(iniciado){
      activityContext.postCalificacionActividad(calificacion)
      if(umaBloque)guardarCalificacion(umaBloque, itemsCorrectos.length, JSON.stringify(itemsCorrectos))
    }
    else if(!iniciado)activityContext.postCalificacionActividad(null)
  }

  const autocompletarActividad = () =>{
    setColumns([...contendoresVacios, ...contenedorItems])
  }

  useEffect(()=>{
    if(!evaluable){
      autocompletarActividad()
    }else if(evaluable){
      setColumns(currentColumns)
    }
  },[evaluable])

  const onDragEnd = (result, columns, setColumns) =>{
    setIniciado(true)
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  }

  const columnsLength = hotspots.length

  return (
    <>
    {instrucciones && <div className='act-instructions px-4 mt-4'>{instrucciones}</div>}
     {/* <div className='flex-1 pl-5 text-[#627d92]'>Instrucciones:  {instrucciones}</div> */}
    <div className='flex flex-col md:flex-row my-6 md:my-10 max-w-activity w-full'>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
      <div className="w-fit grid grid-cols-2 md:flex md:flex-col md:items-center flex-1` rounded-md text-sm md:text-base flex-0 md:pl-4">
          {Object.entries(columns).slice(0,columnsLength).map(([columnId, column], index) => {
            return (
              <div className={`inline-flex items-center justify-between p-1`} key={columnId}>
                <div className={`mr-2 w-[10rem] break-words text-white flex items-center px-4 rounded-lg min-h-[2rem] ${columnsLength > 10 ? '' : 'md:min-h-[3.5rem]'}`} style={{background:colors[index] + '99'}}>
                  <span className='mr-2'>{column.orden + 1}.</span>
                  {column.texto && <span>{column.texto}</span>}
                  {column.audio && column.audio !== '' && (<SimplePlayer audio={typeof column.audio === 'string' ?  column.audio : URL.createObjectURL(column.audio)} size={'text-xl !p-3 mr-2 bg-white !mx-auto'}/>)}
                </div>
                <Droppable
                  droppableId={columnId}
                  key={columnId}
                  isDropDisabled={column.items.length > 0}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`h-[25px] w-[25px] rounded-full border`}
                        style={{background: snapshot.isDraggingOver ? "#18bafb" : "#ebecf0",}}
                      >
                        {column.items.map((item, i) => {
                          return (
                            <>
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={i}
                              >
                                  {(provided, dragSnapshot) => {
                                    return (
                                      <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{userSelect: "none",...provided.draggableProps.style}}
                                          className={`${item.arrastrable ? 'inline-block w-full' : ''} ${dragSnapshot.isDragging ? '!m-0' : ''}`}
                                          >
                                            <div 
                                                className={`text-sm z-[8] text-center rounded-full aspect-square w-[25px] h-[25px] flex items-center justify-center`} 
                                                style={ item.arrastrable ? { background:item.background, color:'#fff'} : {background : '#f8fdff', color:'#6e6d7a'} }>
                                                {item.orden + 1}
                                            </div>
                                      </div>
                                    );
                                  }}
                            </Draggable>
                            </>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
      </div>
      <div className='flex flex-1 md:pl-4'>
        <div className='relative w-full mx-auto h-fit'>
          {Object.entries(columns).slice(columnsLength).map(([columnId, column], index) => {
            return (
              <div 
                className={`inline-block p-1 absolute rounded-full min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px]`} 
                key={columnId}
                style={{width:`${column.actWidth}%` , height:`${column.actHeight}%`, top:`${column.actY}%`,left:`${column.actX}%`}}
                >
                <Droppable
                  droppableId={columnId}
                  key={columnId}
                  direction={'vertical'}
                  isDropDisabled={column.items.length > 0}
                  >
                  {(provided, snapshot) => {
                    return (
                      <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`rounded-full w-full h-full border border-nimbus bg-[#ebecf0]`}
                      style={{background: snapshot.isDraggingOver ? "#18bafb" : "#ebecf0",transition:'all .15s ease-in-out'}}
                      >
                        {column.items.map((item, i) => {
                          return (
                            <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={i}
                            >
                                {(provided, dragSnapshot) => {
                                  return (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{userSelect: "none",...provided.draggableProps.style}}
                                    className={`${item.arrastrable ? 'inline-block w-full  min-h-[1.5rem]' : ''}  ${dragSnapshot.isDragging ? '!m-0' : ''}`}
                                    >
                                          <div 
                                          className={`absolute text-sm z-[8] text-center min-h-[1rem] rounded-full w-[25px] h-[25px] flex items-center justify-center ${item.arrastrable ? 'w-fit px-2' : ''}`} 
                                          style={ item.arrastrable ? { background:item.background, color:'#fff'} : {background : '#f8fdff', color:'#6e6d7a'} }>
                                              {item.orden + 1}
                                          </div>: ''
                                    </div>
                                  );
                                }}
                          </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
          <ImagenValidada
            src={img} 
            className='w-full mx-auto shadow-xl rounded-md border overflow-hidden'
          />
      </div>
      </div>
      </DragDropContext>
    </div>
    {/* <svg width="100%">
      <line x1="0" y1="0" x2="230" y2="200" style={{stroke: '#606060', strokeWidth: 5}}></line>
    </svg> */}
    {evaluable ?
    <button 
    className='mt-4 md:mt-8 border bg-nimbus text-white m-5 py-3 px-10 rounded-full mx-auto block transform scale-75 md:scale-100 cursor-pointer w-fit'
    onClick={()=>calificar()}
    >
      <Trans>{iniciado ? 'Calificar' : 'Cerrar' }</Trans>
    </button>:null
    }
    </>
  )
}

HotspotMultiple.defaultProps = {
  evaluable:true
}

export default HotspotMultiple