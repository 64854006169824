import { getReportesAvisos } from "../../services/Api/reporte-avisos";
import { MESSAGES } from "../../utils/messagesUtils";
import { TablePagination } from "./reporte-avisos-detalles/fragments/TablePagination";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../services/user";
import alertConfiguration from "../../utils/alertsUtils";
import LoadingScreen from "../../fragments/LoadingScreen";
import NoDataMessage from "../../fragments/NoDataMessage";
import ReporteAvisosFilter from "./fragments/ReporteAvisosFilter";
import ReporteAvisosTable from "./fragments/ReporteAvisosTable";
import SubHeaderReporteAvisos from "./fragments/SubHeaderReporteAvisos";
import withUserAccesability from "../../hoc/withUserAccesability";

const FILTRO_REPORTE_AVISOS_INITIAL_VALUES = {
  fechaInicio: "",
  fechaFin: "",
  categoria: "",
  busqueda: "",
};

const itemsPerPage = 10;

const ReporteAvisosHome = ({ isUserAllowed }) => {
  const { user } = useContext(UserContext);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filtro, setFiltro] = useState(FILTRO_REPORTE_AVISOS_INITIAL_VALUES);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const lastPage = Math.ceil(total / 10);

  useEffect(() => {
    if (page > lastPage) setPage(1);
  }, [page, lastPage]);

  useEffect(() => {
    if (user?._id) getAvisosReport();
  }, [
    page,
    filtro.busqueda,
    filtro.categoria,
    filtro?.fechaInicio,
    filtro?.fechaFin,
    user?._id,
  ]);

  // get avisos report list
  const getAvisosReport = async () => {
    const { busqueda, categoria, fechaInicio, fechaFin } = filtro;
    try {
      setIsLoading(true);
      const { total, registros } = await getReportesAvisos(
        user?._id,
        page,
        busqueda,
        categoria,
        fechaInicio,
        fechaFin
      );
      setReports(registros);
      setTotal(total);
    } catch (error) {
      console.log("🚀 ~ getAvisosReport ~ error:", error);
      toast.error(error?.message, alertConfiguration);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
        <SubHeaderReporteAvisos />
        <div
          className={`${
            reports?.length !== 0 && "justify-between"
          } relative flex-1 flex flex-col px-4 sm:px-8`}
        >
          <div>
            <ReporteAvisosFilter
              filtro={filtro}
              setFiltro={setFiltro}
              total={total}
              currentPage={page}
              itemsPerPage={itemsPerPage}
            />
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <ReporteAvisosTable reports={reports} />
            )}
          </div>
          <div>
            {!isLoading && reports?.length === 0 && (
              <NoDataMessage message={MESSAGES.MESSAGES_REPORTE_AVISOS_TABLE} />
            )}

            {!isLoading && reports?.length > 0 && (
              <TablePagination
                page={page}
                setPage={setPage}
                total={total}
                lastPage={lastPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withUserAccesability(ReporteAvisosHome);
