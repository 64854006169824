import React, { useEffect, useState } from "react";
import HerramientaLectorTexto from "./HerramientaLectorTexto";
import Cronometro from "./ActividadesUtils/Cronometro";

function LectorTextoMultiple({
  inputList,
  lang,
  viewSourceText,
  instrucciones,
  speechSpeed,
}) {
  const [listening, setListening] = useState(false);
  const [paused, setPaused] = useState(false);
  const [finished, setFinished] = useState(false);
  const [time, setTime] = useState(0);
  const [currentlyPlaying, setCurrentlyPlaying] = useState("");

  const synth = window.speechSynthesis;

  function speakfnc(texto, contentId) {
    setCurrentlyPlaying(contentId);
    stopfnc();
    const speakText = new SpeechSynthesisUtterance(texto);
    setListening(true);
    setFinished(false);
    setPaused(false);
    //al terminar audio
    speakText.onend = (e) => {
      setFinished(true);
    };
    //config de voz
    const voices = synth.getVoices();
    const esVoice = voices.reverse().find((el) => el.lang === "es-ES");
    const enVoice = voices.find((el) => el.lang === "en-GB");
    speakText.rate = speechSpeed;
    if (lang !== "es") {
      speakText.lang = "en-US";
      speakText.lang = "en-GB";
      speakText.voice = enVoice;
    } else {
      speakText.lang = "es-ES";
      speakText.voice = esVoice;
    }
    synth.speak(speakText);
  }

  //Error chrome con cargas de voz
  useEffect(() => {
    speakfnc("", "");
  }, []);

  function stopfnc() {
    synth.cancel();
  }

  function resumefnc() {
    synth.resume();
    setPaused(false);
  }

  function pausefnc() {
    synth.pause();
    setPaused(true);
  }

  const stopWhenLeaving = () => {
    window.addEventListener("beforeunload", stopfnc);
    stopfnc();
  };

  //Ditiene la voz en caso de iniciar o terminar
  useEffect(() => {
    stopWhenLeaving();
    return () => {
      stopWhenLeaving();
    };
  }, []);

  function restartfnc() {
    synth.cancel();
    setTimeout(() => {
      speakfnc();
    }, 500);
  }

  const recompatibleContent =
    typeof inputList === "string"
      ? [{ texto: inputList, _id: "1", orden: 0 }]
      : inputList;

  return (
    <div>
      {instrucciones && (
        <div className="act-instructions mb-5 !max-w-[700px]">
          {instrucciones}
        </div>
      )}
      {recompatibleContent
        .sort((a, b) => (a.orden > b.orden ? 1 : -1))
        .map((el) => {
          return (
            <>
              <HerramientaLectorTexto
                key={el._id}
                value={el}
                viewSourceText={viewSourceText}
                paused={paused}
                listening={listening}
                finished={finished}
                speakfnc={speakfnc}
                resumefnc={resumefnc}
                restartfnc={restartfnc}
                pausefnc={pausefnc}
                isPlaying={currentlyPlaying === el._id}
              />
            </>
          );
        })}
      <Cronometro
        time={time}
        running={true}
        setTime={setTime}
        style={"opacity-0"}
      />
    </div>
  );
}

export default LectorTextoMultiple;
