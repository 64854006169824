import React, { Fragment, useContext, useEffect, useState } from "react";
import SubHeaderAsignarArticulos from "./fragments/SubHeaderAsignarArticulos";
import AsignarArticulosFilter from "./fragments/AsignarArticulosFilter";
import { usePagination } from "../../hooks/usePagination";
import AsignarArticulosTable from "./fragments/AsignarArticulosTable";
import { MESSAGES } from "../../utils/messagesUtils";
import TablePagination from "../../fragments/TablePagination";
import NoDataMessage from "../../fragments/NoDataMessage";
import { UserContext } from "../../services/user";
import withUserAccesability from "../../hoc/withUserAccesability";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingScreen from "../../fragments/LoadingScreen";
import { getGruposCampus, getUserInfo, getNivelesConGrados, getPaises } from "../../services/api";
import { obtenerEstructuraYRelaciones } from "../../services/Api/Autorizacion";
import { obtenerUserEstructuraInfo } from "../../utils/userUtils";
import { getGruposPlantelPaqueteEscolar } from "../../services/Api/Grupos";

const AsignarArticulos = ({ isUserAllowed }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [estructuras, setEstructuras] = useState([]);
  const [estructurasFiltro, setEstructurasFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paises, setPaises] = useState([]);
  const [niveles, setNiveles] = useState([]);

  const obtenerEstructuras = async () => {
    let { tipoEstructuraRequest } = obtenerUserEstructuraInfo(user.user, user.activeRole);
    let estructurasResponse = [];
    const paisesResponse = await getPaises();
    switch (user.activeRole) {
      case "DIR_EDITORIAL":
        estructurasResponse = await obtenerEstructuraYRelaciones(tipoEstructuraRequest, user.user.editorial._id);
        estructurasResponse = estructurasResponse.distribuciones.filter(
          (distribucion) => !distribucion.esEliminado
        );
        break;
      case "DISTRIBUIDOR":
        estructurasResponse = await obtenerEstructuraYRelaciones(tipoEstructuraRequest, user.user.distribucion._id);
        estructurasResponse = estructurasResponse.corporativos.filter(
          (corporativo) =>
          !corporativo.esEliminado
          );
        break;
      case "DIR_CORPORATIVO":
        estructurasResponse = await obtenerEstructuraYRelaciones(tipoEstructuraRequest, user.user.escuela._id);
        estructurasResponse = estructurasResponse.campus.filter(
          (plantel) => !plantel.esEliminado && user.user.escuela.dirFiscalPais._id === plantel.dirFiscalPais._id
        );
        break;
      case "DIR_PLANTEL":
        estructurasResponse = await getGruposPlantelPaqueteEscolar(user.escuela._id, user.escuela.campus._id);
        estructurasResponse = estructurasResponse.filter((grupo) => !grupo.esEliminado);

        const nivelesResponse = await getNivelesConGrados(
          user.escuela._id,
          user.escuela.campus._id
        );
        setNiveles(nivelesResponse.data);
        break;
      default:
        break;
    }

    setEstructuras(estructurasResponse);
    setEstructurasFiltro(estructurasResponse);
    setPaises(paisesResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    if (user.activeRole !== "") {
      if (!isUserAllowed) {
        navigate("/NotFound");
        return;
      } else {
        obtenerEstructuras();
      }
    }
  }, [user.activeRole]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(estructurasFiltro, 15);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-backgroundColor pb-4 min-h-[calc(100vh-4.25rem)] flex flex-col">
          <SubHeaderAsignarArticulos userRole={user.activeRole} />
          <div
            className={`${estructuras.length !== 0 && "justify-between"} relative flex-1 flex flex-col px-4 sm:px-8`}
          >
            <div>
              <AsignarArticulosFilter
                estructuras={estructuras}
                estructurasFiltro={estructurasFiltro}
                setEstructurasFiltro={setEstructurasFiltro}
                userRol={user.activeRole}
                paises={paises}
                niveles={niveles}
              />
              <AsignarArticulosTable estructuras={currentData()} userRol={user.activeRole} />
              {estructuras.length !== 0 && estructurasFiltro.length === 0 && (
                <NoDataMessage message={MESSAGES.MESSAGES_FILTER_ESTRUCTURAS_AGREGADAS} />
              )}
            </div>

            <div>
              {estructuras.length === 0 ? (
                <NoDataMessage message={MESSAGES.MESSAGES_ESTRUCTURAS_AGREGADAS} />
              ) : (
                <TablePagination
                  next={next}
                  prev={prev}
                  jump={jump}
                  data={currentData()}
                  currentPage={currentPage}
                  maxPage={maxPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withUserAccesability(AsignarArticulos);
