import { getRolLabel } from "../../../utils/rolesUtils";
import { ReactComponent as Add } from "../../../../assets/icons/addBlueSmall.svg";
import { toast } from "react-toastify";
import { useAddUsers } from "./AddUsersContext";
import { useListaDifusionDetalles } from "../ListaDifusionDetalles/ListaDifusionDetallesContext";
import alertConfiguration from "../../../utils/alertsUtils";
import { useEffect } from "react";

const AddUsersRow = ({ user, additionalFields }) => {
  const {
    usuario: {
      _id,
      nombres,
      primerApellido,
      segundoApellido,
      correoElectronico,
    },
    estructura,
    esSeleccionado,
  } = user;
  const { setUsersAdded } = useListaDifusionDetalles();
  const {
    destinatarios,
    setDestinatarios,
    setDestinatariosFiltro,
    selectUser,
  } = useAddUsers();

  const handleAddUser = (_id) => {
    const userFound = destinatarios.find((user) => user.usuario._id === _id);
    setUsersAdded((usersAdded) => [
      ...usersAdded,
      { ...userFound, esSeleccionado: false },
    ]);

    const newUsers = destinatarios.filter((user) => user.usuario._id !== _id);
    const unselectNewUsers = newUsers.map((user) => {
      return { ...user, esSeleccionado: false };
    });
    setDestinatarios(unselectNewUsers);
    setDestinatariosFiltro(unselectNewUsers);
    toast.success("Destinatario agregado correctamente", alertConfiguration);
  };

  const fullName = `${nombres} ${primerApellido} ${segundoApellido}`;

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-md border-r-0 text-center font-sans w-20">
        <div className="rounded-md flex flex-row items-center ml-1 gap-2">
          <input
            type="checkbox"
            name={`selectUser`}
            id={`selectUser`}
            className="rounded-md border-2 border-gray-300 my-0 cursor-pointer"
            checked={esSeleccionado ?? false}
            onChange={() => selectUser(user)}
          />
        </div>
      </td>
      <td className="border-y-2 font-sans text-left">{fullName}</td>
      <td className="border-y-2 font-sans text-center">{correoElectronico}</td>
      {additionalFields ? (
        <>
          <td className="border-y-2 font-sans text-center">
            {user.usuario.nivelAcademico
              ? user.usuario.nivelAcademico.nombres
              : "-"}
          </td>
          <td className="border-y-2 font-sans text-center">
            {user.usuario.gradoAcademico
              ? user.usuario.gradoAcademico.nombres
              : "-"}
          </td>
          <td className="border-y-2 font-sans text-center">
            {user.usuario.grupos && user.usuario.grupos.length > 0
              ? user.usuario.grupos.map((grupo) => grupo.nombres).join(", ")
              : "-"}
          </td>
        </>
      ) : (
        <td className="border-y-2 font-sans text-center">{estructura}</td>
      )}
      <td className="border-y-2 font-sans text-center">
        {user.usuario.roles.map((rol) => getRolLabel(rol)).join(", ")}
      </td>
      <td className="border-2 rounded-r-md border-l-0 pb-1 pt-3 text-center">
        <button title="Agregar destinatario" onClick={() => handleAddUser(_id)}>
          <Add className="w-5 h-5 mr-2" />
        </button>
      </td>
    </tr>
  );
};

export default AddUsersRow;
