import DefaultVideo from "../../../assets/icons/icons-bloques/video_default.png";
import { replaceTextLinks } from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";
import VideoValidado from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/VideoValidado";

export default function VideoTexto({
  video,
  texto,
  pieDeFoto,
  invertir,
  background,
}) {
  return (
    <div
      className={`shadow-lg rounded-2xl border border-[#ededed] visor mb-4 flex justify-center w-full flex-col ${
        invertir ? "md:flex-row-reverse" : "md:flex-row"
      }`}
    >
      <div className="w-full md:w-2/4 mx-auto md:m-4">
        <div
          className="my-5 mx-auto w-full max-w-[1280px] bg-black bg-[length:100%_100%] rounded-lg"
          style={{
            backgroundImage: `url(${DefaultVideo})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          {video && video.includes(".mp4") && (
            <div className="video_bg">
              <VideoValidado
                src={video}
                className="rounded-xl shadow-xl w-full h-auto aspect-video"
              />
            </div>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: pieDeFoto }}
          className="mt-2"
        ></div>
      </div>
      <div
        className={`p-2 md:p-4 w-full md:w-2/4 rounded-lg my-4 h-fit ${background}`}
        dangerouslySetInnerHTML={{ __html: replaceTextLinks(texto) }}
      ></div>
    </div>
  );
}
