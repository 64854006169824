import { ROLES } from "./roles";

export const DIR_EDITORIAL_SELECTED_ROL_OPTIONS = [
  { _id: ROLES.editorEditorial, nombre: "Editor editorial" },
  { _id: ROLES.autorEditorial, nombre: "Armador editorial" },
  { _id: ROLES.autorIndependiente, nombre: "Autor independiente" },
];

export const GENERAL_DISTRIBUIDOR_ROL_OPTIONS = [
  { _id: ROLES.distribuidor, nombre: "Distribuidor" },
  { _id: ROLES.asesor, nombre: "Asesor" },
];

export const DISTRIBUIDOR_ROL_OPTIONS = [
  { _id: ROLES.asesor, nombre: "Asesor" },
];

export const CORPORATIVO_ROL_OPTIONS = [
  { _id: ROLES.corporativo, nombre: "Director corporativo" },
];

export const PLANTEL_ROL_OPTIONS = [
  { _id: ROLES.plantel, nombre: "Director de plantel" },
  { _id: ROLES.operativo, nombre: "Administrador operativo" },
  { _id: ROLES.coordinador, nombre: "Coordinador académico" },
  { _id: ROLES.autor, nombre: "Armador escolar" },
  { _id: ROLES.editor, nombre: "Editor escolar" },
  { _id: ROLES.profesor, nombre: "Profesor" },
  { _id: ROLES.alumno, nombre: "Alumno" },
  { _id: ROLES.padre, nombre: "Tutor" },
];

export const DIR_PLANTEL_ROL_OPTIONS = [
  { _id: ROLES.operativo, nombre: "Administrador operativo" },
  { _id: ROLES.coordinador, nombre: "Coordinador académico" },
  { _id: ROLES.autor, nombre: "Armador escolar" },
  { _id: ROLES.editor, nombre: "Editor escolar" },
  { _id: ROLES.profesor, nombre: "Profesor" },
  { _id: ROLES.alumno, nombre: "Alumno" },
  { _id: ROLES.padre, nombre: "Tutor" },
];

export const ADMIN_OPERATIVO_ROL_OPTIONS = [
  { _id: ROLES.coordinador, nombre: "Coordinador académico" },
  { _id: ROLES.autor, nombre: "Armador escolar" },
  { _id: ROLES.editor, nombre: "Editor escolar" },
  { _id: ROLES.profesor, nombre: "Profesor" },
  { _id: ROLES.alumno, nombre: "Alumno" },
  { _id: ROLES.padre, nombre: "Tutor" },
];

export const PROFESOR_ROL_OPTIONS = [
  { _id: ROLES.alumno, nombre: "Alumno" },
  { _id: ROLES.padre, nombre: "Tutor" },
];

export const TODOS_OPTION = [{ _id: "Todos", nombre: "Todos" }];
